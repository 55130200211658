import {
  NetworkRolloutRegions,
  NetworkRolloutRegionsCode,
} from '@app/shared/models/contracts/enums/shared-enums';

export const NETWORK_ROLL_OUT_REGION_OPTIONS = [
  {
    viewValue: NetworkRolloutRegions.UnitedKingdom,
    value: NetworkRolloutRegionsCode.UnitedKingdom,
  },
  { viewValue: NetworkRolloutRegions.Ireland, value: NetworkRolloutRegionsCode.Ireland },
];
