import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Role } from '@app/shared/models/contracts/enums/shared-enums';
import env from '@environment';
import { RootState } from './store';
import api from '@server/api-config';
import { enumKeyByValue } from '@app/shared/helpers';
import { UserSummaryDto, UserUpdateDto } from '@app/shared/models/contracts/user-summary-dto';

export interface UserManagementState {
  addUsersState: AddUsersState;
  editedUserState?: EditedUserState;
}

export interface EditedUserState {
  id: number;
  user?: UserSummaryDto;
}

export type User = {
  id?: number;
  email: string;
  role: Role;
};

export type NewUser = User & {
  validationMessages?: string[];
};

export type AddUsersState = {
  newUsers: NewUser[];
  role: Role;
  regionCodes: string[];
};

const initialState: UserManagementState = {
  addUsersState: {
    newUsers: [],
    role: Role.TeamMember,
    regionCodes: [],
  },
};

export const postNewUsers = createAsyncThunk<NewUser[], void, { state: RootState }>(
  'users/addUsers',
  async (_, thunkAPI) => {
    const state = thunkAPI.getState();
    const users = state.userManagement.addUsersState.newUsers;

    const payload = {
      users: users.map((user) => ({
        email: user.email,
        role: enumKeyByValue(Role, user.role),
      })),
      regionCodes: state.userManagement.addUsersState.regionCodes,
    };

    const response = await api.post(`${env.apiUrl}/users`, JSON.stringify(payload));
    const validationResults = response.data;

    const updatedUsers = users.map((user) => {
      const validationResult = validationResults.find(
        (result: NewUser) => result.email === user.email
      );

      return validationResult
        ? { ...user, validationMessages: validationResult.validationMessages ?? [] }
        : user;
    });

    thunkAPI.dispatch(updateUsersValidation(updatedUsers));

    return response.data;
  }
);

export const putUser = createAsyncThunk<void, UserSummaryDto, { state: RootState }>(
  'users/updateUser',
  async (userData) => {
    const payload: UserUpdateDto = {
      email: userData.email,
      role: userData.role,
      regionCodes: userData.regions?.map((r) => r.code) ?? [],
    };

    await api.put(`${env.apiUrl}/users`, JSON.stringify(payload));
  }
);

export const getUserDetails = createAsyncThunk<void, number, { state: RootState }>(
  '/users/getUserDetails',
  async (userId, thunkAPI) => {
    const response = await api.get(`${env.apiUrl}/users/${userId}`);
    const userSummary = response.data;
    thunkAPI.dispatch(userManagementSlice.actions.updateEditedUser(userSummary));

    return userSummary;
  }
);

const userManagementSlice = createSlice({
  name: 'userManagement',
  initialState,
  reducers: {
    addNewUser: (state, action: PayloadAction<{ email: string }>) => {
      state.addUsersState.newUsers.push({
        ...action.payload,
        role: state.addUsersState.role,
      });
    },

    deleteNewUser: (state, action: PayloadAction<number>) => {
      state.addUsersState.newUsers = state.addUsersState.newUsers.filter(
        (_, i) => i !== action.payload
      );
    },

    setRegionCodes: (state, action: PayloadAction<string[]>) => {
      state.addUsersState.regionCodes = action.payload;
    },

    setRole: (state, action: PayloadAction<Role>) => {
      state.addUsersState.role = action.payload;
      state.addUsersState.newUsers = state.addUsersState.newUsers.map((user) => ({
        ...user,
        role: state.addUsersState.role,
      }));
    },

    updateUsersValidation: (state, action: PayloadAction<NewUser[]>) => {
      state.addUsersState.newUsers = action.payload;
    },

    updateEditedUser(state, action: PayloadAction<UserSummaryDto>) {
      state.editedUserState = {
        id: action.payload.id,
        user: action.payload,
      };
    },

    resetAddUsersState: () => {
      return initialState;
    },
  },
});

export const {
  addNewUser,
  deleteNewUser,
  setRegionCodes,
  setRole,
  updateUsersValidation,
  resetAddUsersState,
} = userManagementSlice.actions;

export default userManagementSlice.reducer;
