export function deepEqualIgnoreOrder(valueA: any, valueB: any): boolean {
  if (valueA === valueB) {
    return true;
  }
  if (typeof valueA !== typeof valueB) {
    return false;
  }
  if (valueA === null || valueB === null) {
    return valueA === valueB;
  }
  if (Array.isArray(valueA) && Array.isArray(valueB)) {
    if (valueA.length !== valueB.length) {
      return false;
    }
    return valueA.every((item, index) => deepEqualIgnoreOrder(item, valueB[index]));
  }
  if (typeof valueA === 'object') {
    const keysA = Object.keys(valueA).sort();
    const keysB = Object.keys(valueB).sort();
    if (keysA.length !== keysB.length) {
      return false;
    }

    return keysA.every(
      (key, i) => key === keysB[i] && deepEqualIgnoreOrder(valueA[key], valueB[key])
    );
  }
  return false;
}
