import { Environment } from '@environment';

export enum DevFeature {
  ERF = 'Equity Refinancing',
  DealThesisERF = 'ShareholderOrdinary Equity Deal Thesis ERF',
  DealThesisHistoricalColumns = 'Deal Thesis Historical Columns',
  ShowLegacyPwerm = 'Show Legacy PWERM',
  TotalEquityChartWithModal = 'Total Equity Chart With Modal',
  PayoutLogicCap = 'Payout Logic Cap',
}

export type DevFeatureList = `${DevFeature}`;

export type FeaturesConfig = Record<DevFeatureList, boolean>;

const localStorageKey = 'enabled-dev-features';
const hideDevFeaturesLinkKey = 'hide-feature-flags-link';

export const showDevFeaturesLink = () => {
  return (
    (window._env.current === Environment.Local ||
      window._env.current === Environment.Development ||
      window._env.current === Environment.Test ||
      window._env.current === Environment.UAT ||
      window._env.current === Environment.Dem) &&
    !window.localStorage.getItem(hideDevFeaturesLinkKey)
  );
};

export const isDevFeatureEnabled = (feature: DevFeatureList): boolean => {
  const enabledFeatures = JSON.parse(window.localStorage.getItem(localStorageKey) ?? '[]');
  try {
    return enabledFeatures.indexOf(feature) > -1;
  } catch (e) {
    return false;
  }
};

export const useDevFeatures = () => {
  const availableFeatures = DevFeature !== undefined ? Object.values(DevFeature) : [];

  const enabledFeatures: DevFeatureList = JSON.parse(
    window.localStorage.getItem(localStorageKey) ?? '[]'
  );

  const updateFeaturesStatus = (featuresConfig: FeaturesConfig) =>
    window.localStorage.setItem(
      localStorageKey,
      JSON.stringify(
        Object.entries(featuresConfig)
          .filter(([_, isEnabled]) => isEnabled)
          .map(([feature]) => feature)
      )
    );

  return {
    availableFeatures,
    enabledFeatures,
    isDevFeatureEnabled,
    updateFeaturesStatus,
  };
};
