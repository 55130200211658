import { useAppDispatch, useAppSelector } from '@app/core/hooks/redux-hooks';
import { useLocale } from '@app/core/hooks/useLocale';
import { getUserDetails, putUser } from '@app/core/store/user-management-slice';
import Button from '@app/shared/components/button/Button';
import { ButtonAppearance } from '@app/shared/components/button/button-enums';
import { Card } from '@app/shared/components/card/Card';
import { CardCornersVariation, CardVariation } from '@app/shared/components/card/card-enums';
import { textFieldFormattingProps } from '@app/shared/components/form-controls/form-field/form-field-patterns';
import FormField from '@app/shared/components/form-controls/form-field/FormField';
import { FormSelect } from '@app/shared/components/form-controls/form-select/FormSelect';
import * as notificationActions from '@core/store/notification-slice';
import { formConfigBase } from '@app/shared/constants/form-config-base';
import {
  NetworkRolloutRegions,
  Role,
  StructureDimension,
} from '@app/shared/models/contracts/enums/shared-enums';
import { FC, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import styles from './edit-user.module.scss';
import { usePwcNavigate } from '@app/core/hooks/routing-hooks';
import { RouteConstants } from '../../RouteConstants';
import { uiValuesSlice } from '@app/core/store/ui-values-slice';
import NoAccessPage from '@app/shared/components/status-pages/NoAccessPage';
import useUserOptions from '@app/core/hooks/useUserOptions';
import { useParams } from 'react-router-dom';
import { SeverityType } from '@app/shared/mui-components/alert/AlertTypes';
import store, { RootState } from '@app/core/store/store';
import { enumKeyByValue } from '@app/shared/helpers';
import { FormSelectMultiple } from '@app/shared/components/form-controls/form-select-multiple/FormSelectMultiple';
import { NETWORK_ROLL_OUT_REGION_OPTIONS } from '@app/shared/constants/dropdown-values/network-rollout-regions';
import { RegionInfoDto } from '@app/shared/models/contracts/userinfo-dto';
import { UserSummaryDto } from '@app/shared/models/contracts/user-summary-dto';
import { getRequiredValidator } from '@app/core/validations/hook-forms/validators';

const PARENT_CLASSNAME = 'edit-user';

export const EditUser: FC = (): JSX.Element => {
  const { l } = useLocale();
  const { userId } = useParams();
  const dispatch = useAppDispatch();
  const { navigate } = usePwcNavigate();

  const formMethods = useForm({
    ...formConfigBase,
    defaultValues: {
      email: '',
      role: enumKeyByValue(Role, Role.TeamMember),
      regions: [] as string[],
    },
  });

  const { isGlobalAdmin, roleOptions, regionOptions } = useUserOptions();

  const [isDisabled, setIsDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [disableAccess, setDisableAccess] = useState(false);

  const editedUser = useAppSelector(
    (state: RootState) => state.userManagement.editedUserState?.user
  );

  useEffect(() => {
    dispatch(uiValuesSlice.actions.setIsLoggedIn());
    dispatch(getUserDetails(Number(userId))).finally(() => setIsLoading(false));
  }, [dispatch, userId]);

  useEffect(() => {
    if (editedUser) {
      formMethods.setValue('email', editedUser.email);
      formMethods.setValue('role', editedUser.role);

      if (editedUser.regions) {
        const regionNames = editedUser.regions.map((r) => r.name);
        formMethods.setValue('regions', regionNames);
      }

      if (!isGlobalAdmin) {
        setDisableAccess(true);
      } else if (editedUser?.role === Role.Administrator) {
        setDisableAccess(true);
      }
    }
  }, [editedUser, formMethods, isGlobalAdmin]);

  const handleCancelButton = () => {
    navigate(`../../${RouteConstants.UserManagement}`);
  };

  const handleRegionsChange = (regions: string[]) => {
    const disableUpdate = regions.length === 0;
    setIsDisabled(disableUpdate);
  };

  const formSubmitHandler = async (data: {
    email: string;
    role: keyof typeof Role;
    regions: string[];
  }) => {
    try {
      const regionsDto: RegionInfoDto[] = [];

      data.regions.map((regionName) => {
        const region = regionName as NetworkRolloutRegions;
        const regionOption = NETWORK_ROLL_OUT_REGION_OPTIONS.find(
          (regionOption) => regionOption.viewValue === region
        );

        if (regionOption) {
          regionsDto.push({
            code: regionOption.value,
            name: regionOption.viewValue,
          });
        }
      });

      await dispatch(
        putUser({
          id: Number(userId),
          email: data.email,
          role: data.role as keyof typeof Role,
          regions: regionsDto,
        } as UserSummaryDto)
      );

      store.dispatch(
        notificationActions.showNotificationSnackbar({
          severity: SeverityType.success,
          message: l('_UserChangedSuccessfully'),
          autoHide: true,
        })
      );

      navigate(`../../${RouteConstants.UserManagement}`);
    } catch (error) {
      store.dispatch(
        notificationActions.showNotificationSnackbar({
          severity: SeverityType.error,
          message: l('_UserUpdateError'),
          autoHide: true,
        })
      );
    }
  };

  if (isLoading) {
    return <></>;
  }

  if (disableAccess) {
    return <NoAccessPage />;
  }

  return (
    <div style={{ maxWidth: StructureDimension.ModalWidth }}>
      <FormProvider {...formMethods}>
        <h2>{l('_EditUser')}</h2>
        <Card
          hasShadow
          cornersVariation={CardCornersVariation.Secondary}
          variation={CardVariation.SecondarySpacing}>
          <form
            data-testid="edit-user-form"
            className={styles[`${PARENT_CLASSNAME}__form`]}
            onSubmit={formMethods.handleSubmit(formSubmitHandler)}>
            <FormField
              name="email"
              label={l('_EmailAddress')}
              readOnly
              disabled
              isGapless
              {...textFieldFormattingProps}
            />
            <div className={styles[`${PARENT_CLASSNAME}__table-spacing`]} />
            <FormSelect
              name="role"
              label={l('_UserRole')}
              options={roleOptions}
              rules={{ ...getRequiredValidator() }}
            />
            <div className={styles[`${PARENT_CLASSNAME}__table-spacing`]} />
            <FormSelectMultiple
              name="regions"
              label={l('_Regions')}
              options={regionOptions}
              rules={{ ...getRequiredValidator() }}
              onSelectedValuesChange={handleRegionsChange}
            />
            <div className={styles[`${PARENT_CLASSNAME}__action-buttons`]}>
              <Button
                appearance={ButtonAppearance.DEFAULT_SECONDARY}
                data-testid="cancel-button"
                onClick={() => handleCancelButton()}
                ignoreReadOnly>
                {l('_Cancel')}
              </Button>
              <Button
                type="submit"
                data-testid="submit-button"
                appearance={ButtonAppearance.DEFAULT_PRIMARY}
                disabled={isDisabled}
                ignoreReadOnly>
                {l('_UpdateUser')}
              </Button>
            </div>
          </form>
        </Card>
      </FormProvider>
    </div>
  );
};
