import { Outlet, RouteObject } from 'react-router-dom';
import AdminRoutesWrapper from './admin-routes-wrapper/AdminRoutesWrapper';
import { UserManagement } from './user-management/UserManagement';
import { RouteConstants } from './RouteConstants';
import PwcNavigate from '@app/shared/routing/PwcNavigate';
import { AddUsers } from './user-management/add-users/AddUsers';
import { EditUser } from './user-management/edit-user/EditUser';

const adminRoutes: RouteObject[] = [
  {
    path: '',
    element: (
      <AdminRoutesWrapper>
        <Outlet />
      </AdminRoutesWrapper>
    ),
    children: [
      {
        index: true,
        element: <PwcNavigate route={RouteConstants.UserManagement} replace />,
      },
      {
        path: RouteConstants.UserManagement,
        element: (
          <AdminRoutesWrapper.PageLayout key={RouteConstants.UserManagement}>
            <Outlet />
          </AdminRoutesWrapper.PageLayout>
        ),
        children: [
          {
            index: true,
            element: (
              <AdminRoutesWrapper.PageLayout key={RouteConstants.UserManagement}>
                <UserManagement />
              </AdminRoutesWrapper.PageLayout>
            ),
          },
          {
            path: RouteConstants.AddUsers,
            element: (
              <AdminRoutesWrapper.PageLayout key={RouteConstants.AddUsers}>
                <AddUsers />
              </AdminRoutesWrapper.PageLayout>
            ),
          },
          {
            path: RouteConstants.EditUser,
            element: (
              <AdminRoutesWrapper.PageLayout key={RouteConstants.EditUser}>
                <EditUser />
              </AdminRoutesWrapper.PageLayout>
            ),
          },
        ],
      },
    ],
  },
];

export default adminRoutes;
