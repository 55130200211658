import Button from '@app/shared/components/button/Button';
import { ButtonAppearance } from '@app/shared/components/button/button-enums';
import { FC, useState } from 'react';
import { EditUserModal } from '@app/modules/users/user-list/edit/EditUserModal';
import { Role } from '@app/shared/models/contracts/enums/shared-enums';
import { useLocale } from '@app/core/hooks/useLocale';
import SvgEditUser from '@app/shared/icons/EditUser';

interface EditUserActionProps {
  email: string;
  role: keyof typeof Role;
}

export const EditUserAction: FC<EditUserActionProps> = ({ email, role }): JSX.Element => {
  const { l } = useLocale();
  const [isEditUserOpen, setIsEditUserOpen] = useState(false);

  return (
    <>
      <Button
        data-testid={`${email}-user-edit-button`}
        startIcon={<SvgEditUser />}
        onClick={() => setIsEditUserOpen(true)}
        appearance={ButtonAppearance.CLEAN_ACTION}>
        {l('_EditUser')}
      </Button>
      {isEditUserOpen && (
        <EditUserModal
          setIsOpen={setIsEditUserOpen}
          isOpen={isEditUserOpen}
          email={email}
          role={role}
        />
      )}
    </>
  );
};
