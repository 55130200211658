import { FC, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import BaseModal from '@app/shared/components/modal/BaseModal';
import { ModalSize } from '@app/shared/components/modal/base-modal-enums';
import { useAppDispatch, useAppSelector } from '@app/core/hooks/redux-hooks';
import { useLocale } from '@app/core/hooks/useLocale';
import { uiValuesSlice } from '@core/store/ui-values-slice';
import CapitalStructureItemFormErf, { EquityItem } from '../../../CapitalStructureItemFormErf';
import {
  AdjustmentMode,
  CalcMethod,
  CapitalStructureEventSeverity,
  CouponType,
  EquityRefinancingMovementType,
  EventKey,
  InstrumentType,
  OwnerType,
  StructureDimension,
} from '@app/shared/models/contracts/enums/shared-enums';
import { useParams } from 'react-router-dom';
import Sidebar, { SidebarVariation } from '@app/shared/components/sidebar/Sidebar';
import ExclamationTriangleSvg from '@app/shared/icons/legacy/ExclamationTriangleSvg';
import {
  EquityRefinancingEventDto,
  EquityRefinancingEventOwnerMovementDto,
  EquityRefinancingEventProRataMovementDto,
  ProjectDto,
} from '@app/shared/models/contracts/project-dto';
import styles from '../../build-structure-tab/capital-structure.module.scss';
import InstrumentTileErf from '../InstrumentTileErf';
import { MAX_INSTRUMENT_COUNT } from '@app/shared/constants/capital-structure';
import useIsReadOnly from '@app/core/hooks/customUseIsReadOnly';
import { FormProvider, useForm } from 'react-hook-form';
import { formConfigBase } from '@app/shared/constants/form-config-base';
import FormField from '@app/shared/components/form-controls/form-field/FormField';
import { textFieldFormattingProps } from '@app/shared/components/form-controls/form-field/form-field-patterns';
import {
  maxLengthValidator,
  getRequiredValidator,
  uniqueEventNarrative,
  dateBetweenValidator,
} from '@app/core/validations/hook-forms/validators';
import { calculateBuildStructure } from '@app/core/store/capital-structure-slice';
import { useDrop } from 'react-dnd';
import {
  cloneDeep,
  enumKeyByValue,
  getNewInstrument,
  getProjectDraftWithAddedInstrument,
  getProjectDraftWithInstrumentAddedToEvent,
} from '@app/shared/helpers';
import {
  getEventInstrumentsFromEvent,
  selectCapitalStructure,
} from '@app/core/store/capital-structure-slice-selectors';
import {
  CapitalStructureBuildStructureDto,
  CapitalStructureEventDto,
} from '@app/shared/models/contracts/capital-structure-debt-instrument-dto';
import SvgWarningStatus from '@app/shared/icons/WarningStatus';
import SvgExclamationMarkCircled from '@app/shared/icons/ExclamationMarkCircled';
import FaultIcon from '../FaultIcon';
import classNames from 'classnames';
import SvgEventInformation from '@app/shared/icons/EventInformation';
import FormDatePicker from '@app/shared/components/form-controls/form-date-picker/FormDatePicker';
import { EventUpdateState } from '../CapitalStructureErf';
import { deepEqualIgnoreOrder } from '@app/shared/helpers/clone/compare-deep-normalised';
import Button from '@app/shared/components/button/Button';
import { ButtonAppearance, ButtonSize } from '@app/shared/components/button/button-enums';

interface CopyEventModalProps {
  setIsOpen: (value: boolean) => void;
  isOpen: boolean;
  buildStructureEvent: CapitalStructureEventDto;
  instrumentId?: string;
  modalRanks: JSX.Element[];
  openingEventState: EventUpdateState | null;
  openingProjectState: ProjectDto;
  currentEventState: EventUpdateState | null;
  setOpeningProjectState: (project: ProjectDto) => void;
  setOpeningEventState: (event: EventUpdateState) => void;
}

const PARENT_CLASSNAME = 'capital-structure';

export const EditEventModal: FC<CopyEventModalProps> = ({
  isOpen,
  setIsOpen,
  buildStructureEvent,
  instrumentId,
  modalRanks,
  openingEventState,
  openingProjectState,
  currentEventState,
  setOpeningProjectState,
  setOpeningEventState,
}): JSX.Element | null => {
  const { l } = useLocale();
  const { caseId } = useParams();
  const dispatch = useAppDispatch();
  const isReadOnly = useIsReadOnly();
  const maxEventNameLength = useAppSelector(
    (state) => state.uiValues.maxRefinancingEventNameLength
  );
  const project = useAppSelector((state) => state.project.projectDraft);
  const isOpmOnly = project.details.calcMethod === CalcMethod.OPM;
  const capitalStructures = project.capitalStructures;
  const capitalStructureKey = Object.keys(capitalStructures)?.[0];
  const capitalStructure = useAppSelector(selectCapitalStructure);
  const capitalStructureResults = useAppSelector(
    (state) => state.capitalStructure.values.buildStructures[capitalStructureKey]
  );
  const buildStructures = useAppSelector((state) => state.capitalStructure.values.buildStructures);

  const events = capitalStructure.events;
  const eventNarrative = events[buildStructureEvent.id]?.narrative;
  const eventDate = events[buildStructureEvent.id]?.eventDate;
  const existingEvents = isOpmOnly
    ? Object.fromEntries(
        capitalStructure.eventSets[EventKey.OpmOnly]?.events.map((eventId) => [
          eventId,
          capitalStructure.events[eventId],
        ]) ?? []
      )
    : Object.fromEntries(
        Object.entries(events).filter(
          ([eventId, _]) => !capitalStructure.eventSets[EventKey.OpmOnly]?.events.includes(eventId)
        )
      );
  const nonSelectedEvents = Object.fromEntries(
    Object.entries(existingEvents).filter(([key, _]) => key !== buildStructureEvent.id)
  );
  const instrumentDefinitions = buildStructures?.[capitalStructureKey]?.instrumentDefinitions;
  const eventInstruments = getEventInstrumentsFromEvent(buildStructureEvent, instrumentDefinitions);
  const buildStructure = buildStructures[capitalStructureKey];
  const isEvent = !(buildStructureEvent.id === EventKey.InitialCapitalStructure);
  const eventSetId = isOpmOnly
    ? EventKey.OpmOnly
    : project.pwermInput.cases.find((caseItem) => caseItem.caseId === caseId)?.eventSetId ??
      EventKey.EmptyEventSet;
  const selectedEvent = buildStructure.eventSets[eventSetId]?.events.find(
    (event) => event.id === buildStructureEvent.id
  );

  const [, drop] = useDrop(
    () => ({
      accept: [
        InstrumentType.ShareholderLoanNotes,
        InstrumentType.PreferredShares,
        InstrumentType.OrdinaryEquity,
      ],
      drop(_item: string, monitor) {
        const draggedInstrumentType = monitor.getItemType() as InstrumentType;
        addInstrument(draggedInstrumentType, buildStructureEvent.id);
      },
      collect: (monitor) => ({
        isActive: monitor.canDrop() && monitor.isOver(),
      }),
    }),
    [project, eventInstruments]
  );

  const findEarliestNextEventDate = (
    currentEventId: string,
    capitalStructures: CapitalStructureBuildStructureDto
  ): string | undefined => {
    const { eventSets } = capitalStructures;
    const nextDates: string[] = [];

    Object.values(eventSets || {}).forEach((set) => {
      const sequenceEntries = Object.entries(set.sequence || {});
      // Sort by the numeric key
      sequenceEntries.sort((a, b) => parseInt(a[0], 10) - parseInt(b[0], 10));

      // Build an ordered list of event IDs
      const orderedIds = sequenceEntries.map(([_, seq]) => seq.eventId);

      // Find current event index in the orderedIds list
      const thisIndex = orderedIds.indexOf(currentEventId);
      // Check next one by position in sequence
      if (thisIndex !== -1 && thisIndex < orderedIds.length - 1) {
        const nextId = orderedIds[thisIndex + 1];
        const nextEvent = (set.events || []).find((ev) => ev.id === nextId);
        if (nextEvent?.date) {
          nextDates.push(nextEvent.date);
        }
      }
    });

    if (!nextDates.length) {
      return undefined;
    }
    nextDates.sort();
    return nextDates[0];
  };

  const findLatestPreviousEventDate = (
    currentEventId: string,
    capitalStructures: CapitalStructureBuildStructureDto
  ): string | undefined => {
    const { eventSets } = capitalStructures;
    const previousDates: string[] = [];

    Object.values(eventSets || {}).forEach((set) => {
      const sequenceEntries = Object.entries(set.sequence || {});
      // Sort by the numeric key
      sequenceEntries.sort((a, b) => parseInt(a[0], 10) - parseInt(b[0], 10));

      // Build an ordered list of event IDs
      const orderedIds = sequenceEntries.map(([_, seq]) => seq.eventId);

      // Find the current event index in the ordered list
      const thisIndex = orderedIds.indexOf(currentEventId);
      // Check the one before by position in sequence
      if (thisIndex > 0) {
        const prevId = orderedIds[thisIndex - 1];
        const prevEvent = (set.events || []).find((ev) => ev.id === prevId);
        if (prevEvent?.date) {
          previousDates.push(prevEvent.date);
        }
      }
    });

    if (!previousDates.length) {
      return undefined;
    }
    // The "latest" means we want the maximum date
    previousDates.sort();
    return previousDates[previousDates.length - 1];
  };

  const nextEventDate = findEarliestNextEventDate(buildStructureEvent.id, capitalStructureResults);
  const previousEventDate =
    findLatestPreviousEventDate(buildStructureEvent.id, capitalStructureResults) ??
    project.investmentDate;

  const addInstrument = async (instrumentType: InstrumentType, eventId: string): Promise<void> => {
    const newInstrumentId = uuidv4();
    const newInstrument = getNewInstrument(
      project,
      eventInstruments,
      instrumentType,
      newInstrumentId
    );

    const updateProjectDraft =
      eventId !== EventKey.InitialCapitalStructure
        ? getProjectDraftWithInstrumentAddedToEvent({ draft: project, eventId, newInstrument })
        : getProjectDraftWithAddedInstrument({ draft: project, newInstrument });

    await dispatch(calculateBuildStructure({ project: updateProjectDraft, ignoreErrors: true }));

    dispatch(
      uiValuesSlice.actions.setActiveInstrument({
        instrumentId: newInstrumentId,
        eventId: buildStructureEvent.id,
      })
    );
  };

  const formMethods = useForm<EquityRefinancingEventDto>({
    ...formConfigBase,
    mode: 'onChange',
    defaultValues: {
      narrative: eventNarrative,
      eventDate: eventDate,
    },
  });

  const formValuesContainValidationErrors = Object.keys(formMethods.formState.errors).length > 0;

  useEffect(() => {
    if (isOpen) {
      formMethods.reset({
        ...formMethods.getValues(),
        narrative: eventNarrative,
        eventDate: eventDate,
      });
    }
  }, [eventNarrative, eventDate, formMethods, isOpen]);

  const [isResetEventModalVisible, setResetEventModalVisible] = useState<boolean>(false);
  const [rerenderKey, setRerenderKey] = useState<number>(0);

  if (!buildStructures) {
    return null;
  }

  const isInitialEqualToCurrent = deepEqualIgnoreOrder(openingEventState, currentEventState);

  const handleReset = async () => {
    await dispatch(calculateBuildStructure({ project: openingProjectState, ignoreErrors: true }));
    setRerenderKey((prevKey) => prevKey + 1);
    if (isEvent) {
      formMethods.reset({
        narrative:
          openingProjectState.capitalStructures[capitalStructureKey].events[buildStructureEvent.id]
            .narrative,
        eventDate:
          openingProjectState.capitalStructures[capitalStructureKey].events[buildStructureEvent.id]
            .eventDate,
      });
    }
    setResetEventModalVisible(false);
  };

  const getEquityItem = (instrumentId: string) => {
    const instrumentDefinition = capitalStructure.instrumentDefinitions[instrumentId];
    const initialValues = capitalStructure.initialValues[instrumentId];
    const selectedInstrument = selectedEvent?.ranks
      .flatMap((rank) => Object.entries(rank.instruments))
      .find(([instrument, _]) => {
        return instrument === instrumentId;
      })?.[1];

    if (!selectedInstrument || !instrumentDefinition) {
      return null;
    }

    const getEventValues = () => {
      let couponValue = null;
      let couponStartDate = null;
      let couponType = enumKeyByValue(CouponType, CouponType.CompoundInterest);
      let cashPayProportion = 0;
      let ownerMovements: Nullable<Record<string, EquityRefinancingEventOwnerMovementDto>> = null;
      let proRataMovement: Nullable<EquityRefinancingEventProRataMovementDto> = null;
      const movements = capitalStructure.events[selectedEvent.id].movements;
      const instrumentMovements = movements[instrumentId];
      if (instrumentId && selectedEvent && capitalStructure.events[selectedEvent.id]) {
        if (Object.keys(movements).includes(instrumentId)) {
          if (instrumentMovements?.coupon) {
            couponValue = instrumentMovements.coupon.value;
            couponStartDate = instrumentMovements.coupon.date;
            couponType = instrumentMovements?.coupon?.type ?? couponType;
            cashPayProportion = instrumentMovements?.coupon?.cashPayProportion ?? 0;
          } else {
            couponStartDate = selectedInstrument.coupon?.date ?? project.investmentDate;
            couponType =
              selectedInstrument.coupon?.type ??
              enumKeyByValue(CouponType, CouponType.CompoundInterest);
            cashPayProportion = selectedInstrument.coupon?.cashPayProportion ?? 0;
          }

          ownerMovements = instrumentMovements?.ownerMovements;
          proRataMovement = instrumentMovements?.proRataMovement ?? null;
        } else {
          couponStartDate = selectedInstrument.coupon?.date ?? project.investmentDate;
          couponType =
            selectedInstrument.coupon?.type ??
            enumKeyByValue(CouponType, CouponType.CompoundInterest);
          cashPayProportion = selectedInstrument.coupon?.cashPayProportion ?? 0;
        }
      }

      const currentActiveCoupon = selectedInstrument.coupon;

      const isProRataAmountClosing = proRataMovement?.closingAmount != null;
      const isProRataSharesClosing = proRataMovement?.closingNumberOfShares != null;
      const isProRataAmountDelta = proRataMovement?.amount != null;
      const isProRataSharesDelta = proRataMovement?.numberOfShares != null;

      return {
        ...selectedInstrument,
        ...instrumentDefinition,
        // this is required because this can be null in the capitalStructure but needs a defined value in the form
        isSweetEquity: instrumentDefinition.isSweetEquity ?? false,
        proRataAmount:
          proRataMovement?.amount ??
          (isProRataAmountClosing ? selectedInstrument?.totalValueDelta : null),
        proRataShares:
          proRataMovement?.numberOfShares ??
          (isProRataSharesClosing ? selectedInstrument?.totalSharesDelta : null),
        proRataClosingAmount:
          proRataMovement?.closingAmount ??
          (isProRataAmountDelta ? selectedInstrument?.totalValueAfterMovements : null),
        proRataClosingShares:
          proRataMovement?.closingNumberOfShares ??
          (isProRataSharesDelta ? selectedInstrument?.totalSharesAfterMovements : null),
        proRataAmountInputMode: isProRataAmountClosing
          ? AdjustmentMode.Absolute
          : isProRataAmountDelta
          ? AdjustmentMode.Relative
          : null,
        proRataSharesInputMode: isProRataSharesClosing
          ? AdjustmentMode.Absolute
          : isProRataSharesDelta
          ? AdjustmentMode.Relative
          : null,
        instrumentId: instrumentId,
        coupon: {
          value: couponValue != null ? couponValue * 100 : null,
          date: couponStartDate ?? project.investmentDate,
          type: couponType,
          cashPayProportion: cashPayProportion * 100,
        },
        currentActiveCoupon: currentActiveCoupon,
        ownership: Object.keys(OwnerType).map((owner) => {
          const isOwnerAmountClosing = ownerMovements?.[owner]?.closingAmount != null;
          const isOwnerSharesClosing = ownerMovements?.[owner]?.closingNumberOfShares != null;
          const isOwnerAmountDelta = ownerMovements?.[owner]?.amount != null;
          const isOwnerSharesDelta = ownerMovements?.[owner]?.numberOfShares != null;
          return {
            owner: owner as keyof typeof OwnerType,
            amount:
              ownerMovements && Object.keys(ownerMovements).includes(owner)
                ? ownerMovements[owner]?.amount ??
                  (isOwnerAmountClosing ? selectedInstrument?.valueDeltas[owner] : null)
                : null,
            numberOfShares:
              ownerMovements && Object.keys(ownerMovements).includes(owner)
                ? ownerMovements[owner]?.numberOfShares ??
                  (isOwnerSharesClosing ? selectedInstrument?.shareDeltas[owner] : null)
                : null,
            currentAmount: selectedInstrument.ownershipAfterMovements
              ? selectedInstrument.ownershipAfterMovements[owner]?.amount
              : null,
            currentNumberOfShares: selectedInstrument.ownershipAfterMovements
              ? selectedInstrument.ownershipAfterMovements[owner]?.numberOfShares
              : null,
            closingAmount:
              ownerMovements && Object.keys(ownerMovements).includes(owner)
                ? ownerMovements[owner]?.closingAmount ??
                  (isOwnerAmountDelta
                    ? selectedInstrument?.ownershipAfterMovements[owner]?.amount
                    : null)
                : null,
            closingNumberOfShares:
              ownerMovements && Object.keys(ownerMovements).includes(owner)
                ? ownerMovements[owner]?.closingNumberOfShares ??
                  (isOwnerSharesDelta
                    ? selectedInstrument?.ownershipAfterMovements[owner]?.numberOfShares
                    : null)
                : null,
            amountInputMode:
              ownerMovements && Object.keys(ownerMovements).includes(owner)
                ? isOwnerAmountClosing
                  ? AdjustmentMode.Absolute
                  : isOwnerAmountDelta
                  ? AdjustmentMode.Relative
                  : null
                : null,
            numberOfSharesInputMode:
              ownerMovements && Object.keys(ownerMovements).includes(owner)
                ? isOwnerSharesClosing
                  ? AdjustmentMode.Absolute
                  : isOwnerSharesDelta
                  ? AdjustmentMode.Relative
                  : null
                : null,
          };
        }),
      };
    };

    const getInitialStructureValues = () => {
      const couponValues = capitalStructure.initialValues[instrumentId].coupon;
      return {
        ...selectedInstrument,
        ...instrumentDefinition,
        // this is required because this can be null in the capitalStructure but needs a defined value in the form
        isSweetEquity: instrumentDefinition.isSweetEquity ?? false,
        instrumentId: instrumentId!,
        coupon: {
          value: (couponValues?.value ?? 0) * 100,
          date: couponValues?.date ?? project.investmentDate,
          type: couponValues?.type ?? enumKeyByValue(CouponType, CouponType.CompoundInterest),
          cashPayProportion: (couponValues?.cashPayProportion ?? 0) * 100,
        },
        ownership: Object.keys(OwnerType).map((owner) => {
          return {
            owner: owner as keyof typeof OwnerType,
            amount: initialValues?.ownership[owner]?.amount ?? null,
            numberOfShares: initialValues?.ownership[owner]?.numberOfShares ?? null,
            currentAmount: null,
            currentNumberOfShares: null,
          };
        }),
      };
    };

    const getValues = () => {
      const values = isEvent ? getEventValues() : getInitialStructureValues();
      return values;
    };

    return getValues();
  };

  const equityItem: EquityItem | null = instrumentId ? getEquityItem(instrumentId) : null;

  const formSubmitHandler = () => {
    dispatch(
      calculateBuildStructure({
        project: {
          ...project,
          capitalStructures: {
            ...project.capitalStructures,
            [capitalStructureKey]: {
              ...capitalStructure,
              events: {
                ...capitalStructure.events,
                [buildStructureEvent.id]: {
                  ...capitalStructure.events[buildStructureEvent.id],
                  narrative: formMethods.getValues().narrative,
                  eventDate: formMethods.getValues().eventDate,
                },
              },
            },
          },
        },
        ignoreErrors: true,
      })
    );
  };

  const handleModalClose = () => {
    setIsOpen(false);

    if (buildStructureEvent.id !== EventKey.InitialCapitalStructure) {
      const currentEventMovements = cloneDeep(events[buildStructureEvent.id]?.movements ?? {});

      // Remove empty value adjustment movements where the ownership split has been updated but without any owner/pro-rata movements
      Object.keys(currentEventMovements).forEach((key) => {
        const movement = currentEventMovements[key];
        if (
          movement.ownershipSplit &&
          movement.movementType === EquityRefinancingMovementType.ValueAdjustment &&
          movement.ownerMovements &&
          Object.keys(movement.ownerMovements).length === 0 &&
          Object.keys(movement).length === 3
        ) {
          delete currentEventMovements[key];
        }
      });

      const updatedProjectDraft = {
        ...project,
        capitalStructures: {
          ...project.capitalStructures,
          [capitalStructureKey]: {
            ...capitalStructure,
            events: {
              ...capitalStructure.events,
              [buildStructureEvent.id]: {
                ...capitalStructure.events[buildStructureEvent.id],
                movements: currentEventMovements,
                narrative: !formMethods.formState.errors.narrative
                  ? formMethods.getValues().narrative
                  : eventNarrative,
                eventDate: !formMethods.formState.errors.eventDate
                  ? formMethods.getValues().eventDate
                  : eventDate,
              },
            },
          },
        },
      };

      dispatch(calculateBuildStructure({ project: updatedProjectDraft, ignoreErrors: true }));
    }

    dispatch(
      uiValuesSlice.actions.setActiveInstrument({
        instrumentId: undefined,
        eventId: undefined,
      })
    );
  };

  const handleApply = () => {
    setOpeningEventState(currentEventState);
    setOpeningProjectState(project);
  };

  const previousEventHasErrors = (): boolean => {
    if (!selectedEvent || selectedEvent.date === undefined) {
      return false;
    }

    const previousEvents = buildStructure.eventSets[eventSetId]?.events
      .filter((event) => event.date < selectedEvent.date)
      .map((event) => event.id);

    const previousEventsFaults = buildStructure.eventSets[eventSetId]?.faults.filter(
      (fault) =>
        previousEvents.includes(fault.event ?? '') &&
        fault.severity === CapitalStructureEventSeverity.Error
    );

    return previousEventsFaults?.length > 0;
  };

  const hasErrors = previousEventHasErrors();

  const shouldDisableTiles =
    Object.keys(instrumentDefinitions).length >= MAX_INSTRUMENT_COUNT || isReadOnly || hasErrors;

  const informationFaults = capitalStructureResults.faults.filter(
    (fault) =>
      fault.severity === CapitalStructureEventSeverity.Information &&
      fault.event === buildStructureEvent.id &&
      (fault.eventSet === null || fault.eventSet === eventSetId)
  );

  const warningFaults = capitalStructureResults.faults.filter(
    (fault) =>
      fault.severity === CapitalStructureEventSeverity.Warning &&
      fault.event === buildStructureEvent.id &&
      (fault.eventSet === null || fault.eventSet === eventSetId)
  );

  const errorFaults = capitalStructureResults.faults.filter(
    (fault) =>
      fault.severity === CapitalStructureEventSeverity.Error &&
      fault.event === buildStructureEvent.id &&
      (fault.eventSet === null || fault.eventSet === eventSetId)
  );

  return (
    <BaseModal
      size={ModalSize.Large}
      title={l('_EditEvent')}
      disabled={!isInitialEqualToCurrent}
      isOpen={isOpen}
      preventModalFullHeight
      shouldCloseOnOverlayClick={isInitialEqualToCurrent}
      shouldCloseOnEsc={isInitialEqualToCurrent}
      onRequestClose={handleModalClose}
      onCloseButtonClick={handleModalClose}>
      <div
        className={classNames(styles[`${PARENT_CLASSNAME}`], {
          [styles[`${PARENT_CLASSNAME}__event-modal-header`]]:
            buildStructureEvent.id !== EventKey.InitialCapitalStructure,
          [styles[`${PARENT_CLASSNAME}__initial-structure-modal-header`]]:
            buildStructureEvent.id === EventKey.InitialCapitalStructure,
        })}>
        {buildStructureEvent.id !== EventKey.InitialCapitalStructure && (
          <FormProvider {...formMethods}>
            <form
              className={styles[`${PARENT_CLASSNAME}__form`]}
              onBlur={formMethods.handleSubmit(formSubmitHandler)}>
              <div className={styles[`${PARENT_CLASSNAME}__event-date`]}>
                <FormDatePicker
                  name="eventDate"
                  label={l('_EventDate')}
                  placeholder={l('_EventDate')}
                  inlineLabel
                  isModalHeaderField
                  rules={{
                    ...getRequiredValidator(),
                    validate: {
                      dateMustBeWithinRange:
                        previousEventDate || nextEventDate
                          ? dateBetweenValidator(previousEventDate, nextEventDate).validate
                          : () => true,
                    },
                  }}
                />
              </div>
              <div className={styles[`${PARENT_CLASSNAME}__event-narrative`]}>
                <FormField
                  name="narrative"
                  label={l('_EventNarrative')}
                  placeholder={l('_Narrative')}
                  inlineLabel
                  isModalHeaderField
                  {...textFieldFormattingProps}
                  rules={{
                    ...getRequiredValidator(),
                    ...uniqueEventNarrative(nonSelectedEvents),
                    ...maxLengthValidator(maxEventNameLength),
                  }}
                />
              </div>
            </form>
          </FormProvider>
        )}
        <div className={styles[`${PARENT_CLASSNAME}__controls`]}>
          <div className={styles[`${PARENT_CLASSNAME}__errors`]}>
            <FaultIcon
              severity={CapitalStructureEventSeverity.Information}
              icon={<SvgEventInformation />}
              label="_Information"
              eventFaults={informationFaults}
              instrumentDefinitions={instrumentDefinitions}
              isWithinEditEventModal
            />
            <FaultIcon
              severity={CapitalStructureEventSeverity.Warning}
              icon={<SvgWarningStatus />}
              label="_Warnings"
              eventFaults={warningFaults}
              instrumentDefinitions={instrumentDefinitions}
              isWithinEditEventModal
            />
            <FaultIcon
              severity={CapitalStructureEventSeverity.Error}
              icon={<SvgExclamationMarkCircled />}
              label="_Errors"
              eventFaults={errorFaults}
              instrumentDefinitions={instrumentDefinitions}
              isWithinEditEventModal
            />
          </div>
          <Button
            appearance={ButtonAppearance.DEFAULT_SECONDARY}
            size={ButtonSize.SMALL}
            onClick={() => setResetEventModalVisible(true)}
            disabled={isInitialEqualToCurrent}>
            {l('_Reset')}
          </Button>
          <Button
            appearance={ButtonAppearance.DEFAULT_PRIMARY}
            size={ButtonSize.SMALL}
            onClick={handleApply}
            disabled={
              errorFaults.length > 0 || isInitialEqualToCurrent || formValuesContainValidationErrors
            }>
            {l('_Apply')}
          </Button>
        </div>
      </div>
      <div className={styles[`${PARENT_CLASSNAME}__event-modal-instruments`]}>
        <div className={styles[`${PARENT_CLASSNAME}__event-build`]}>
          <div className={styles[`${PARENT_CLASSNAME}__actions`]}>
            {Object.entries(InstrumentType).map(([key, value]) => (
              <InstrumentTileErf
                key={key}
                instrumentType={value}
                isDisabled={shouldDisableTiles}
                isErfModal
              />
            ))}
          </div>
          <div
            ref={drop}
            style={{
              width:
                instrumentId && equityItem
                  ? StructureDimension.ModalWidth
                  : StructureDimension.ModalWithoutFormWidth,
            }}
            className={
              instrumentId && equityItem
                ? styles[`${PARENT_CLASSNAME}__event-structure`]
                : styles[`${PARENT_CLASSNAME}__event-structure-without-form`]
            }>
            {modalRanks.map((rank) => {
              return rank;
            })}
          </div>
        </div>
        {hasErrors ? (
          <Sidebar
            sidebarVariation={SidebarVariation.Empty}
            data-testid="instrument-details-sidebar"
            isErfModal>
            <ExclamationTriangleSvg className={styles[`${PARENT_CLASSNAME}__icon`]} />
            <span>{l('_EventsWithErrors')}</span>
          </Sidebar>
        ) : (
          instrumentId &&
          equityItem && (
            <CapitalStructureItemFormErf
              key={instrumentId + rerenderKey}
              equityItem={equityItem}
              eventSetId={eventSetId}
            />
          )
        )}
      </div>
      <BaseModal
        size={ModalSize.Small}
        title={l('_ResetEventValues')}
        buttonConfirmLabel={l('_Reset')}
        buttonCancelLabel={l('_Cancel')}
        onCloseButtonClick={() => setResetEventModalVisible(false)}
        onConfirm={handleReset}
        onCancel={() => setResetEventModalVisible(false)}
        isOpen={isResetEventModalVisible}>
        {l('_ThisWillResetTheEventBackToThePreviousValues')}
      </BaseModal>
    </BaseModal>
  );
};
