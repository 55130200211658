import { SVGProps } from 'react';
const SvgAmended = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M4.164 3.6L5.928 5.364C6 5.428 6.084 5.46 6.18 5.46C6.276 5.46 6.356 5.428 6.42 5.364C6.484 5.3 6.516 5.22 6.516 5.124C6.516 5.028 6.484 4.948 6.42 4.884L3.828 2.304L1.248 4.884C1.184 4.948 1.152 5.028 1.152 5.124C1.152 5.22 1.184 5.3 1.248 5.364C1.312 5.428 1.392 5.46 1.488 5.46C1.584 5.46 1.664 5.428 1.728 5.364L3.504 3.6L3.516 12H0L0 0.996C0 0.724 0.0999999 0.488 0.3 0.288C0.492 0.096 0.724 0 0.996 0L7.836 0L7.812 8.4L6.048 6.636C5.984 6.572 5.908 6.54 5.82 6.54C5.724 6.54 5.644 6.572 5.58 6.636C5.516 6.7 5.484 6.78 5.484 6.876C5.484 6.972 5.516 7.052 5.58 7.116L8.172 9.696L10.752 7.116C10.816 7.052 10.848 6.972 10.848 6.876C10.848 6.78 10.816 6.7 10.752 6.636C10.688 6.572 10.608 6.54 10.512 6.54C10.416 6.54 10.336 6.572 10.272 6.636L8.496 8.4L8.52 0L11.004 0C11.276 0 11.508 0.096 11.7 0.288C11.9 0.488 12 0.724 12 0.996L12 11.004C12 11.284 11.9 11.52 11.7 11.712C11.508 11.904 11.276 12 11.004 12L4.188 12L4.164 3.6Z"
      fill="white"
    />
  </svg>
);
export default SvgAmended;
