import { FC, useCallback, useEffect, useState } from 'react';
import styles from './header.module.scss';
import { useAppSelector } from '@core/hooks/redux-hooks';
import Button from '@app/shared/components/button/Button';
import { ButtonAppearance } from '@app/shared/components/button/button-enums';
import SvgLogo from '@app/shared/icons/Logo';
import { Breadcrumbs } from '@app/shared/components/breadcrumbs/Breadcrumbs';
import { useLocation, useParams } from 'react-router-dom';
import PwcLink from '@app/shared/routing/PwcLink';
import { Avatar } from '@mui/material';
import { DropdownMenu } from '@app/shared/components/dropdown/DropdownMenu';
import env from '@environment';
import SvgLogout from '@app/shared/icons/Logout';
import { SaveProjectAction } from '@app/modules/projects/inputs/project-admin/SaveProjectAction';
import { SpinnerLoader } from '@app/shared/components/loader/SpinnerLoader';
import { LoaderSize } from '@app/shared/components/loader/loader-enums';
import { LoaderType, useLoader } from '@core/hooks/useLoader';
import { CurrentActiveUsersIndicator } from '@app/shared/components/active-users/CurrentActiveUsersIndicator';
import { RunOpmButtonAndDropdown } from '@app/shared/components/run-opm-button/RunOpmButtonAndDropdown';
import { CalcMethod, ProjectStatus, Role } from '@app/shared/models/contracts/enums/shared-enums';
import { ExportButtonDropdown } from './ExportButtonDropdown';
import { useLocale } from '@app/core/hooks/useLocale';
import SvgSetting from '@app/shared/icons/Setting';
import { featureAvailabilityWithRegions } from '@app/core/utils/feature-availability';
import { enumKeyByValue } from '@app/shared/helpers';
import { CompatibilityLevelUpgradeModal } from '@app/shared/components/compatibility-level-upgrade/CompatibilityLevelUpgradeModal';
import { CompatibilityLevelUpgradeNotice } from '@app/shared/components/compatibility-level-upgrade/CompatibilityLevelUpgradeNotice';
import { FormSelect } from '@app/shared/components/form-controls/form-select/FormSelect';
import { formConfigBase } from '@app/shared/constants/form-config-base';
import { FormProvider, SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form';
import { RegionFormModel } from '@app/modules/projects/inputs/region/region-form-types';

const PARENT_CLASSNAME = 'header';

const Header: FC = (): JSX.Element => {
  const { l } = useLocale();
  const location = useLocation();
  const { projectId } = useParams();
  const isAuthenticated = useAppSelector((state) => state.uiValues.isAuthenticated);
  const projectIdRegex = /^\/projects\/\d+/;
  const isSaveButtonVisible = projectIdRegex.test(location?.pathname) && isAuthenticated;
  const { versionTag } = useAppSelector((state) => state.uiValues);
  const { projectDraft } = useAppSelector((state) => state.project);
  const calcMethod = projectDraft?.details?.calcMethod;
  const isOpmEnabled = calcMethod && calcMethod !== CalcMethod.PWERM;
  const isRunOpmButtonVisible = projectIdRegex.test(location?.pathname) && isOpmEnabled;
  const { userInfo } = useAppSelector((state) => state.userInfo);
  const [isAdmin, setIsAdmin] = useState(false);
  const { compatibilityLevel: compatibilityLevelConfig } = useAppSelector(
    (state) => state.uiValues
  );
  const {
    projectDraft: { status, compatibilityLevel },
  } = useAppSelector((state) => state.project);
  const [showUpgradeModal, setShowUpgradeModal] = useState<boolean>(false);
  const showUpgradeNotice =
    projectIdRegex.test(location?.pathname) &&
    compatibilityLevelConfig.currentLevel &&
    compatibilityLevel < compatibilityLevelConfig.currentLevel &&
    status !== ProjectStatus.Closed;

  const formMethods = useForm<RegionFormModel>({
    ...formConfigBase,
    defaultValues: {
      region: env.region,
    },
  });

  const formSubmitHandler = useCallback(async () => {
    const { region } = formMethods.getValues();
    if (region !== env.region) {
      const regionInfo = userInfo.regions?.find((reg) => reg.code === region);
      if (regionInfo?.url) {
        window.location.href = regionInfo.url;
      }
    }
  }, [formMethods, userInfo.regions]);

  const regionOptions =
    userInfo.regions?.map((region) => ({
      value: region.code,
      viewValue: region.name,
    })) ?? [];

  const extractUserInitials = (userName: string) =>
    userName
      .split(' ')
      .map((element) => element[0])
      .join('');

  const [state, setState] = useState(() => ({
    userInitials: '',
    userEmail: '',
    userName: '',
  }));

  useEffect(() => {
    setState({
      userInitials: extractUserInitials(userInfo.fullUserName),
      userEmail: userInfo.email,
      userName: userInfo.fullUserName,
    });

    const isAdminStatus = featureAvailabilityWithRegions(
      [enumKeyByValue(Role, Role.Administrator), enumKeyByValue(Role, Role.RegionAdministrator)],
      userInfo
    );
    setIsAdmin(isAdminStatus);
  }, [userInfo]);

  const isLoading = useLoader(LoaderType.NonBlocking);

  return (
    <div className={styles[PARENT_CLASSNAME]}>
      <div className={styles[`${PARENT_CLASSNAME}__primary-container`]}>
        <PwcLink route="/" aria-label="Home">
          <SvgLogo className={styles[`${PARENT_CLASSNAME}__logo`]} aria-hidden />
        </PwcLink>
        <span className={styles[`${PARENT_CLASSNAME}__app-name`]}>
          {l('_EquityRewardAnalytics')}
        </span>
        <Breadcrumbs />
      </div>
      <div className={styles[`${PARENT_CLASSNAME}__middle-container`]}>
        <CurrentActiveUsersIndicator />
      </div>
      <div className={styles[`${PARENT_CLASSNAME}__secondary-container`]}>
        {isLoading && (
          <div className={styles[`${PARENT_CLASSNAME}__loader-container`]}>
            <div className={styles[`${PARENT_CLASSNAME}__loader-icon`]}>
              <SpinnerLoader size={LoaderSize.Small} />
            </div>
            {l('_Working')}
          </div>
        )}
        {showUpgradeNotice && (
          <CompatibilityLevelUpgradeNotice setShowModal={setShowUpgradeModal} />
        )}
        {isRunOpmButtonVisible && <RunOpmButtonAndDropdown />}
        {isSaveButtonVisible && <ExportButtonDropdown />}
        {isSaveButtonVisible && <SaveProjectAction />}
        {!isSaveButtonVisible && regionOptions.length > 1 && (
          <>
            <FormProvider {...formMethods}>
              <form
                data-cy="regionDropdown"
                onChange={formMethods.handleSubmit(
                  formSubmitHandler as SubmitHandler<RegionFormModel>,
                  formSubmitHandler as SubmitErrorHandler<RegionFormModel>
                )}>
                <FormSelect name="region" isGapless options={regionOptions} />
              </form>
            </FormProvider>
          </>
        )}
        {isAdmin && (
          <Button
            to="/admin"
            className={styles[`${PARENT_CLASSNAME}__settings-button`]}
            appearance={ButtonAppearance.CLEAN}
            ignoreReadOnly>
            <SvgSetting />
          </Button>
        )}
        <DropdownMenu
          ariaLabel="User Profile"
          dropdownContent={
            <Avatar className={styles[`${PARENT_CLASSNAME}__avatar`]}>{state.userInitials}</Avatar>
          }
          ignoreReadOnly>
          <h5 className={styles[`${PARENT_CLASSNAME}__header`]}> {state.userName}</h5>
          <p title={state.userEmail} className={styles[`${PARENT_CLASSNAME}__sub-header`]}>
            {state.userEmail}
          </p>
          <div className={styles[`${PARENT_CLASSNAME}__feedback-link-container`]}>
            <a
              href="https://docs.google.com/forms/d/e/1FAIpQLSekOERw6QLHmJthG2tQ2vTINB74z3f13BWyJgb2vH0davmOnA/viewform?usp=sf_link"
              rel="noreferrer"
              target="_blank"
              className={styles[`${PARENT_CLASSNAME}__feedback-link`]}>
              {l('_GiveFeedback')}
            </a>
          </div>
          <Button
            to={`${env.apiUrl}/account/logout?redirectUri=${document.URL}`}
            className={styles[`${PARENT_CLASSNAME}__logout`]}
            startIcon={<SvgLogout width={16} />}
            appearance={ButtonAppearance.DEFAULT_SECONDARY}
            ignoreReadOnly>
            {l('_LogOut')}
          </Button>
          {versionTag && (
            <div className={styles[`${PARENT_CLASSNAME}__version-tag`]}>
              {l('_EquityRewardAnalytics', { version: versionTag })}
            </div>
          )}
        </DropdownMenu>
      </div>
      <CompatibilityLevelUpgradeModal
        key={projectId}
        showModal={showUpgradeModal}
        setShowModal={setShowUpgradeModal}
      />
    </div>
  );
};

export default Header;
