import {
  CapitalStructureRankDto,
  CapitalStructureRankInstrumentDto,
  CapitalStructureInstrumentDefinitionDto,
} from '@app/shared/models/contracts/capital-structure-debt-instrument-dto';

type CapitalStructureRankInstrumentAndDefinition = CapitalStructureRankInstrumentDto &
  CapitalStructureInstrumentDefinitionDto;

export const cleanFaultsAndMergeInstrumentDefinitions = (
  ranks: CapitalStructureRankDto[],
  instrumentDefinitions: Record<string, CapitalStructureInstrumentDefinitionDto>
) => {
  if (!ranks) {
    return [];
  }

  return ranks.map((rank: CapitalStructureRankDto) => ({
    ...rank,
    instruments: Object.entries(rank.instruments).reduce((acc, [key, instrument]) => {
      const definition = instrumentDefinitions[key];
      acc[key] = {
        ...instrument,
        faults: [],
        ...(definition || {}),
      };
      return acc;
    }, {} as Dictionary<CapitalStructureRankInstrumentAndDefinition>),
  }));
};
