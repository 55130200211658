import { useAppDispatch, useAppSelector } from '@core/hooks/redux-hooks';
import { FC, useCallback, useEffect, useMemo } from 'react';
import { usePwcNavigate } from '@core/hooks/routing-hooks';
import CellValue from '@app/shared/components/cell-value/CellValue';
import { CellValueTheme } from '@app/shared/components/cell-value/cell-value-theme';
import { Card } from '@app/shared/components/card/Card';
import { shortDateViewRules } from '@app/shared/components/cell-value/CellValueConfigurations';
import { CardCornersVariation, CardVariation } from '@app/shared/components/card/card-enums';
import { DropdownMenu } from '@app/shared/components/dropdown/DropdownMenu';
import { CopyProjectAction } from '@app/modules/projects/inputs/project-admin/copy-project/CopyProjectAction';
import { DeleteProjectAction } from '@app/modules/projects/inputs/project-admin/DeleteProjectAction';
import SvgThreeDots from '@app/shared/icons/ThreeDots';
import { Pill, PillSize, PillTheme } from '@app/shared/components/pill/Pill';
import { ProjectStatus, CalcMethod } from '@app/shared/models/contracts/enums/shared-enums';
import { enumKeyByValue } from '@app/shared/helpers';
import { featureAvailability } from '@core/utils/feature-availability';
import classNames from 'classnames';
import { Alignment } from '@app/shared/enums/alignment.enum';
import { ProjectAccessAction } from '@app/modules/projects/inputs/project-admin/project-access/ProjectAccessAction';
import TablePagination from '@app/shared/components/table/TablePagination';
import * as projectListActions from '@core/store/project-list-slice';
import { Toggle } from '@app/shared/components/toggle/Toggle';
import { formConfigBase } from '@app/shared/constants/form-config-base';
import { FormProvider, useForm } from 'react-hook-form';
import styles from './project-list.module.scss';
import { usePaginationHelper } from '@app/core/hooks/customUsePaginationHelper';
import { SearchParamKey } from '@app/shared/models/search-param-enum';
import { SearchField } from '@app/shared/components/form-controls/search-field/SearchField';
import { withDebounce } from '@app/shared/helpers/withDebounce';
import { SEARCH_MIN_CHAR_COUNT } from '@app/shared/constants/configs/search';
import { RouteConstants } from '@app/modules/projects/RouteConstants';
import { useLocale } from '@app/core/hooks/useLocale';
import { resetUserSelections } from '@app/core/store/ui-values-slice';
import { clearOpmSampleWaterfallResults } from '@app/core/store/opm-calculation-slice';
import MaintenanceCard from '../../maintenance/MaintenanceCard';
import { DevFeature, useDevFeatures } from '@app/core/utils/dev-feature';

const PARENT_CLASSNAME = 'project-list';

type ProjectListFormModel = {
  filterMyProjects: boolean;
  search: string;
};

const ProjectList: FC = (): JSX.Element => {
  const { isDevFeatureEnabled } = useDevFeatures();
  const isLegacyPwermEnabled = isDevFeatureEnabled(DevFeature.ShowLegacyPwerm);
  const projectList = useAppSelector((state) => state.projectList.values);
  const showOnlyUserAccessibleProjects = useAppSelector(
    (state) => state.uiValues.showOnlyUserAccessibleProjects
  );
  const dispatch = useAppDispatch();
  const { generatePath, navigate } = usePwcNavigate();
  const { userInfo } = useAppSelector((state) => state.userInfo);
  const { l } = useLocale();

  const {
    searchParams,
    checkIfItemsAreOutOfRange,
    initializePagination,
    getPagedRequestParams,
    setSearchParams,
  } = usePaginationHelper();

  const currentPage = searchParams.get(SearchParamKey.CurrentPage);
  const searchQuery = searchParams.get(SearchParamKey.Search);
  const onlyMyProjects = searchParams.get(SearchParamKey.FilterMyProjects);

  const formMethods = useForm<ProjectListFormModel>({
    ...formConfigBase,
    defaultValues: {
      filterMyProjects: true,
      search: searchParams.get(SearchParamKey.Search) ?? '',
    },
  });

  const { getValues, handleSubmit } = formMethods;

  useEffect(() => {
    initializePagination();
    dispatch(resetUserSelections());
    dispatch(clearOpmSampleWaterfallResults());
    // hook is needed only on initial render
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (currentPage) {
      dispatch(
        projectListActions.fetchProjectList({
          ...getPagedRequestParams(projectList),
          filterMyProjects: onlyMyProjects !== 'false',
          search: searchQuery,
        })
      );
    }
  }, [currentPage, searchQuery, onlyMyProjects]); // eslint-disable-line react-hooks/exhaustive-deps

  const submitSearchData = useCallback(() => {
    const params = [
      {
        key: SearchParamKey.Search,
        value:
          getValues('search') && getValues('search').length >= SEARCH_MIN_CHAR_COUNT
            ? getValues('search')
            : null,
      },
      {
        key: SearchParamKey.CurrentPage,
        value: '0',
      },
    ];

    if (!showOnlyUserAccessibleProjects) {
      params.push({
        key: SearchParamKey.FilterMyProjects,
        value: (getValues('filterMyProjects') ?? false).toString(),
      });
    }

    setSearchParams(...params);

    checkIfItemsAreOutOfRange(projectList);
  }, [
    checkIfItemsAreOutOfRange,
    getValues,
    projectList,
    setSearchParams,
    showOnlyUserAccessibleProjects,
  ]);

  const formOnChangeHandler = useMemo(
    () => withDebounce(handleSubmit(submitSearchData)),
    [handleSubmit, submitSearchData]
  );

  const getRedirectionPath = (calcMethod: string) => {
    return calcMethod === CalcMethod.OPM
      ? `:projectId/${RouteConstants.DashboardOpm}`
      : isLegacyPwermEnabled
      ? `:projectId/${RouteConstants.DashboardPwermLegacy}`
      : `:projectId/${RouteConstants.DashboardPwerm}`;
  };

  return (
    <div className={styles[PARENT_CLASSNAME]}>
      {featureAvailability(['Administrator', 'RegionAdministrator', 'TeamMember'], userInfo) && (
        <FormProvider {...formMethods}>
          <form
            onChange={formOnChangeHandler}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                event.preventDefault();
              }
            }}
            className={styles[`${PARENT_CLASSNAME}__actions`]}>
            <div className={styles[`${PARENT_CLASSNAME}__search`]}>
              <SearchField
                name="search"
                placeholder="Search for a project name"
                onClear={() => {
                  setSearchParams({ key: SearchParamKey.Search, value: null });
                }}
              />
            </div>
            {!showOnlyUserAccessibleProjects && featureAvailability(['TeamMember'], userInfo) && (
              <div className={styles[`${PARENT_CLASSNAME}__toggle`]}>
                <Toggle name="filterMyProjects" label={l('_OnlyShowMyProjects')} />
              </div>
            )}
          </form>
        </FormProvider>
      )}
      <MaintenanceCard />
      <Card
        hasShadow
        cornersVariation={CardCornersVariation.Secondary}
        variation={CardVariation.TertiarySpacing}>
        <>
          <table className="table-primary table-primary--fixed table-primary--secondary-theme table-primary--list">
            <colgroup>
              <col />
              <col className="table-primary__col--additional-data" />
              <col className="table-primary__col--additional-data" />
              <col className="table-primary__col--additional-data" />
              <col className="table-primary__col--action" />
            </colgroup>
            <thead>
              <tr>
                <th>
                  <span className="heading-3 heading--table-header">{l('_Name')}</span>
                </th>
                <th>
                  <span className="heading-3 heading--table-header">{l('_Edited')}</span>
                </th>
                <th>
                  <span className="heading-3 heading--table-header">{l('_Created')}</span>
                </th>
                <th className="">
                  <span className="heading-3 heading--table-header">{l('_Status')}</span>
                </th>
                <th className="" />
              </tr>
            </thead>
            <tbody>
              {projectList.data?.map((project, index) => (
                <tr
                  key={index}
                  {...(featureAvailability(['TeamMember'], userInfo, project.hasAccess) && {
                    onClick: () =>
                      navigate(
                        generatePath(getRedirectionPath(project.calcMethod), {
                          projectId: project.id.toString(),
                        })
                      ),
                  })}
                  className={classNames('table-primary__row--clickable', {
                    'table-primary__row--disabled': !featureAvailability(
                      ['TeamMember'],
                      userInfo,
                      project.hasAccess
                    ),
                  })}>
                  <td>{project.name}</td>
                  <td>
                    <CellValue
                      value={project.editedDate}
                      theme={CellValueTheme.Secondary}
                      {...shortDateViewRules}
                      alignment={Alignment.Left}
                    />
                  </td>
                  <td>
                    <CellValue
                      value={project.createdDate}
                      theme={CellValueTheme.Secondary}
                      {...shortDateViewRules}
                      alignment={Alignment.Left}
                    />
                  </td>
                  <td>
                    <Pill
                      theme={
                        project.status === enumKeyByValue(ProjectStatus, ProjectStatus.Draft)
                          ? PillTheme.Secondary
                          : project.status === enumKeyByValue(ProjectStatus, ProjectStatus.Closed)
                          ? PillTheme.Tertiary
                          : undefined
                      }
                      size={PillSize.Small}>
                      {ProjectStatus[project.status]}
                    </Pill>
                  </td>
                  <td
                    onClick={(event) => event.stopPropagation()}
                    className="table-primary__actions">
                    <DropdownMenu
                      ariaLabel={l('_ProjectOptions')}
                      borderless
                      dropdownTriggerIcon={<SvgThreeDots />}>
                      <>
                        {featureAvailability(['TeamMember'], userInfo, project.hasAccess) && (
                          <li className="action-list-item">
                            <CopyProjectAction
                              projectId={project.id}
                              projectName={project.name}
                              isSaveBeforeCopy={false}
                            />
                          </li>
                        )}
                        <li className="action-list-item">
                          <ProjectAccessAction
                            projectId={project.id}
                            projectName={project.name}
                            userHasAccess={project.hasAccess}
                          />
                        </li>
                        {featureAvailability(
                          ['TeamMember', 'RegionAdministrator', 'Administrator'],
                          userInfo,
                          project.hasAccess
                        ) && (
                          <li className="action-list-item action-list-item--danger">
                            <DeleteProjectAction
                              projectId={project.id}
                              projectName={project.name}
                              shouldRedirectAfter={false}
                              disabled={project.status === ProjectStatus.Closed}
                            />
                          </li>
                        )}
                      </>
                    </DropdownMenu>
                  </td>
                </tr>
              ))}
              {projectList.data?.length === 0 && (
                <tr>
                  <td colSpan={5}>
                    <div className={styles[`${PARENT_CLASSNAME}__content-placeholder`]}>
                      <div className={styles[`${PARENT_CLASSNAME}__no-results-message`]}>
                        {l('_NoResultsFound')}
                      </div>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          {projectList.metadata && <TablePagination paginationInfo={projectList.metadata} />}
        </>
      </Card>
    </div>
  );
};

export default ProjectList;
