import { ButtonAppearance, ButtonSize } from '@app/shared/components/button/button-enums';
import { DropdownMenu } from '@app/shared/components/dropdown/DropdownMenu';
import styles from './export-button-dropdown.module.scss';
import classNames from 'classnames';
import SvgDownload from '@app/shared/icons/Download';
import {
  CalcMethod,
  ClientScheduleExportType,
} from '@app/shared/models/contracts/enums/shared-enums';
import Button from '@app/shared/components/button/Button';
import { FC, useState } from 'react';
import SvgChevronPrimary from '@app/shared/icons/ChevronPrimary';
import { useLocale } from '@app/core/hooks/useLocale';
import { OpmCalculationType } from '@app/core/store/opm-calculation-slice';
import { enumKeyByValue } from '@app/shared/helpers';
import ExportPasswordProtectModal from './ExportPasswordProtectModal';
import { useAppDispatch, useAppSelector } from '@app/core/hooks/redux-hooks';
import * as exportSlice from '@app/core/store/export-slice';

const PARENT_CLASSNAME = 'exportButtonDropdown';

export const ExportButtonDropdown: FC = () => {
  const dispatch = useAppDispatch();
  const {
    projectDraft: projectDraft,
    isOpmValid: isOpmValid,
    isPwermValid: isPwermValid,
    isPwermInputsValid: isPwermInputsValid,
  } = useAppSelector((state) => state.project);
  const { realWorldValues: realWorldValues, riskFreeValues: riskFreeValues } = useAppSelector(
    (state) => state.opmCalculation
  );
  const { l } = useLocale();

  const isPwerm = projectDraft.details.calcMethod === CalcMethod.PWERM;
  const isOpm = projectDraft.details.calcMethod === CalcMethod.OPM;
  const isPwermAndOpm =
    projectDraft.details.calcMethod === enumKeyByValue(CalcMethod, CalcMethod.PWERM_AND_OPM);
  const isOpmRealWorld = projectDraft.opmInput.realWorldRate !== null;
  const pwermExportDisabled = isOpm || !isPwermValid;
  const opmRiskNeutralDisabled = isPwerm || Object.keys(riskFreeValues).length === 0 || !isOpmValid;
  const opmRealWorldDisabled =
    !isOpmRealWorld || isPwerm || Object.keys(realWorldValues).length === 0 || !isOpmValid;

  const [exportFileType, setExportFileType] = useState<Nullable<ClientScheduleExportType>>(null);
  const [isExportPasswordModalOpen, setIsExportPasswordModalOpen] = useState(false);

  const opmDownload = (type: OpmCalculationType) => {
    dispatch(exportSlice.exportOpm({ projectId: projectDraft.id, type: type }));
  };

  const handleSchedulesExport = (type: ClientScheduleExportType) => {
    setExportFileType(type);
    setIsExportPasswordModalOpen(true);
  };

  const handleModalClose = () => {
    setIsExportPasswordModalOpen(false);
    setExportFileType(null);
  };

  return (
    <>
      <DropdownMenu
        useDefaultSvgColour
        dropdownContent={l('_Export')}
        dropdownStartIcon={<SvgDownload />}
        dropdownButtonSize={ButtonSize.SMALL}
        appearance={ButtonAppearance.DEFAULT_SECONDARY}
        menuDropdownClassName="__menu-export"
        dropdownTriggerIcon={<SvgChevronPrimary />}>
        <>
          <h3 className={classNames(styles[`${PARENT_CLASSNAME}__dropdown-title`])}>
            {l('_SchedulesXlsx')}
          </h3>
          {!isOpm && (
            <Button
              data-testid="export-button-dropdown-schedules-pwerm"
              disabled={pwermExportDisabled || !isPwermValid || !isPwermInputsValid}
              appearance={ButtonAppearance.CLEAN}
              className={classNames(styles[`${PARENT_CLASSNAME}__dropdown-item`])}
              onClick={() => handleSchedulesExport(ClientScheduleExportType.PWERM)}>
              {l('_Pwerm')}
            </Button>
          )}
          {!isPwerm && (
            <Button
              data-testid="export-button-dropdown-schedules-opm"
              disabled={opmRiskNeutralDisabled}
              appearance={ButtonAppearance.CLEAN}
              className={classNames(styles[`${PARENT_CLASSNAME}__dropdown-item`])}
              onClick={() => handleSchedulesExport(ClientScheduleExportType.OPM)}>
              {l('_Opm')}
            </Button>
          )}
          {isPwermAndOpm && (
            <Button
              data-testid="export-button-dropdown-schedules-pwerm-and-opm"
              disabled={opmRiskNeutralDisabled || pwermExportDisabled}
              appearance={ButtonAppearance.CLEAN}
              className={classNames(styles[`${PARENT_CLASSNAME}__dropdown-item`])}
              onClick={() => handleSchedulesExport(ClientScheduleExportType.PWERM_AND_OPM)}>
              {l('_PwermAndOpm')}
            </Button>
          )}
          {!isPwerm && (
            <>
              <h3 className={classNames(styles[`${PARENT_CLASSNAME}__dropdown-title`])}>
                {l('_OpmSimulationsDataXlsx')}
              </h3>
              <Button
                data-testid="export-button-dropdown-risk-neutral"
                disabled={opmRiskNeutralDisabled}
                appearance={ButtonAppearance.CLEAN}
                className={classNames(styles[`${PARENT_CLASSNAME}__dropdown-item`])}
                onClick={() => opmDownload(OpmCalculationType.RiskFree)}>
                {l('_OPMRiskNeutral')}
              </Button>
              {isOpmRealWorld && (
                <Button
                  data-testid="export-button-dropdown-real-world"
                  disabled={opmRealWorldDisabled}
                  appearance={ButtonAppearance.CLEAN}
                  className={classNames(styles[`${PARENT_CLASSNAME}__dropdown-item`])}
                  onClick={() => opmDownload(OpmCalculationType.RealWorld)}>
                  {l('_OPMRealWorld')}
                </Button>
              )}
            </>
          )}
        </>
      </DropdownMenu>
      <ExportPasswordProtectModal
        key={projectDraft.id}
        onClose={() => handleModalClose()}
        isOpen={isExportPasswordModalOpen}
        exportFileType={exportFileType}
      />
    </>
  );
};
