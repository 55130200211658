/**
This is used to clean up the adjustments object in instances where the movements at 
the instrument or owner level amount to 0
 */
import { cloneDeep } from '@app/shared/helpers';
import {
  CapitalStructureMovementDto,
  MovementSummaryDto,
} from '@app/shared/models/contracts/capital-structure-debt-instrument-dto';

export const cleanSummary = (summary: MovementSummaryDto): MovementSummaryDto => {
  const summaryCopy = cloneDeep(summary);
  // Handle ownerTypeSummary
  for (const key in summaryCopy.ownerTypeSummary) {
    const val = summaryCopy.ownerTypeSummary[key];
    if (val.amount === 0 && val.numberOfShares === 0) {
      delete summaryCopy.ownerTypeSummary[key];
    }
  }
  if (Object.keys(summaryCopy.ownerTypeSummary).length === 0) {
    summaryCopy.ownerTypeSummary = {};
  }

  // Handle instrumentTypeSummary
  for (const instrKey in summaryCopy.instrumentTypeSummary) {
    const instrVal = summaryCopy.instrumentTypeSummary[instrKey];
    // Remove if total is zero
    if (instrVal.total.amount === 0 && instrVal.total.numberOfShares === 0) {
      delete summaryCopy.instrumentTypeSummary[instrKey];
      continue;
    }
    // If totalInstrument exists, remove nested zero entries
    if (instrVal.totalInstrument) {
      for (const tKey in instrVal.totalInstrument) {
        const subVal = instrVal.totalInstrument[tKey];
        if (subVal.total.amount === 0 && subVal.total.numberOfShares === 0) {
          delete instrVal.totalInstrument[tKey];
          continue;
        }
      }
    }
  }
  if (Object.keys(summaryCopy.instrumentTypeSummary).length === 0) {
    summaryCopy.instrumentTypeSummary = {};
  }

  return summaryCopy;
};

export const cleanMovements = (
  movements: CapitalStructureMovementDto
): CapitalStructureMovementDto => {
  return {
    removals: cleanSummary(movements.removals),
    adjustments: cleanSummary(movements.adjustments),
    additions: cleanSummary(movements.additions),
  };
};
