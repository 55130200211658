import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from '@core/hooks/redux-hooks';
import { usePaginationHelper } from '@app/core/hooks/customUsePaginationHelper';
import { SearchParamKey } from '@app/shared/models/search-param-enum';
import { enumKeyByValue } from '@app/shared/helpers';
import { OrderType, Role } from '@app/shared/models/contracts/enums/shared-enums';
import { featureAvailabilityWithRegions } from '@app/core/utils/feature-availability';
import { withDebounce } from '@app/shared/helpers/withDebounce';
import styles from './user-management.module.scss';
import FormCheckbox from '@app/shared/components/form-controls/form-checkbox/FormCheckbox';
import { SearchField } from '@app/shared/components/form-controls/search-field/SearchField';
import { formConfigBase } from '@app/shared/constants/form-config-base';
import { SEARCH_MIN_CHAR_COUNT } from '@app/shared/constants/configs/search';
import * as userListActions from '@core/store/user-list-slice';
import { Card } from '@app/shared/components/card/Card';
import { CardCornersVariation, CardVariation } from '@app/shared/components/card/card-enums';
import CellValue from '@app/shared/components/cell-value/CellValue';
import { CellValueTheme } from '@app/shared/components/cell-value/cell-value-theme';
import { Alignment } from '@app/shared/enums/alignment.enum';
import TablePagination from '@app/shared/components/table/TablePagination';
import SvgSortingAscendant from '@app/shared/icons/SortingAscendant';
import SvgSorting from '@app/shared/icons/Sorting';
import Button from '@app/shared/components/button/Button';
import { ButtonAppearance } from '@app/shared/components/button/button-enums';
import SvgPlus from '@app/shared/icons/Plus';
import { useLocale } from '@app/core/hooks/useLocale';
import { usePwcNavigate } from '@app/core/hooks/routing-hooks';
import { RouteConstants } from '../RouteConstants';
import BaseModal from '@app/shared/components/modal/BaseModal';
import { ModalSize } from '@app/shared/components/modal/base-modal-enums';
import { UserSummaryDto } from '@app/shared/models/contracts/user-summary-dto';
import SvgEditOutline from '@app/shared/icons/EditOutline';
import SvgEventDelete from '@app/shared/icons/EventDelete';
import useUserOptions from '@app/core/hooks/useUserOptions';

const PARENT_CLASSNAME = 'user-management';

type UserListFormModel = {
  search: string;
  selectedUsers: string[];
  selectAll: string[];
};

export const UserManagement: FC = (): JSX.Element => {
  const { l } = useLocale();
  const { generatePath, navigate } = usePwcNavigate();
  const {
    searchParams,
    checkIfItemsAreOutOfRange,
    initializePagination,
    getPagedRequestParams,
    setSearchParams,
  } = usePaginationHelper();
  const dispatch = useAppDispatch();

  const { isGlobalAdmin, currentUserRoleKey, currentUser } = useUserOptions();

  const formMethods = useForm<UserListFormModel>({
    ...formConfigBase,
    defaultValues: {
      search: searchParams.get(SearchParamKey.Search) ?? '',
      selectedUsers: [],
      selectAll: [],
    },
  });

  const { getValues, handleSubmit, setValue, watch } = formMethods;

  const selectedUsers = watch('selectedUsers');
  const isUsersSelected = watch('selectedUsers')?.length > 0;

  const userList = useAppSelector((state) => state.userPagedList.values);
  const [sort, setSort] = useState({ keyToSort: 'email', orderType: OrderType.ASC });
  const [isDeleteUserModalOpen, setIsDeleteUserModalOpen] = useState(false);

  const currentPage = searchParams.get(SearchParamKey.CurrentPage);
  const searchQuery = searchParams.get(SearchParamKey.Search);

  useEffect(() => {
    const selectableUserIds =
      userList.data
        ?.filter((u) => canDeleteUser(currentUserRoleKey, u.role))
        .map((u) => u.id.toString()) ?? [];

    if (selectedUsers.length === selectableUserIds.length && selectableUserIds.length > 0) {
      setValue('selectAll', ['selectAll']);
    } else {
      setValue('selectAll', []);
    }

    checkIfItemsAreOutOfRange(userList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUsers, userList.data, currentUserRoleKey, setValue]);

  useEffect(() => {
    setValue('selectedUsers', []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  const canDeleteUser = (currentUserRole: keyof typeof Role, targetUserRole: keyof typeof Role) => {
    if (isGlobalAdmin) {
      return targetUserRole !== Role.Administrator;
    }
    return false;
  };

  const canEditUser = (currentUserRole: keyof typeof Role, targetUserRole: keyof typeof Role) => {
    return targetUserRole !== currentUserRole;
  };

  const selectableUsersExist = userList.data?.some((u) =>
    canDeleteUser(currentUserRoleKey, u.role)
  );

  const handleSelectAllChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setValue(
        'selectedUsers',
        userList.data
          .filter((u) => canDeleteUser(currentUserRoleKey, u.role))
          .map((u) => u.id.toString())
      );
    } else {
      setValue('selectedUsers', []);
    }
  };

  const headers = [
    {
      label: (
        <FormCheckbox
          name="selectAll"
          options={[{ value: 'selectAll', viewValue: '' }]}
          onChange={handleSelectAllChange}
          className={styles[`${PARENT_CLASSNAME}__checkbox`]}
          disabled={!selectableUsersExist}
        />
      ),
      key: 'select',
      sortable: false,
    },
    { label: l('_EmailAddress'), key: 'email', sortable: true },
    { label: l('_Regions'), key: 'regions', sortable: false },
    { label: l('_AccessLevel'), key: 'role', sortable: true },
    { label: l('_Actions'), key: 'actions', sortable: false },
  ];

  if (!isGlobalAdmin) {
    headers.shift();
    headers.pop();
  }

  useEffect(() => {
    initializePagination();
    // hook is needed only on initial render
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (currentPage) {
      dispatch(
        userListActions.fetchUserPagedList({
          orderBy: sort.keyToSort,
          orderType: sort.orderType,
          search: searchQuery,
          ...getPagedRequestParams(userList),
        })
      );
    }
  }, [currentPage, searchQuery]); // eslint-disable-line react-hooks/exhaustive-deps

  const submitSearchData = useCallback(() => {
    const params = [
      {
        key: SearchParamKey.Search,
        value:
          getValues('search') && getValues('search').length >= SEARCH_MIN_CHAR_COUNT
            ? getValues('search')
            : null,
      },
      {
        key: SearchParamKey.CurrentPage,
        value: '0',
      },
    ];

    setSearchParams(...params);

    checkIfItemsAreOutOfRange(userList);
  }, [checkIfItemsAreOutOfRange, getValues, setSearchParams, userList]);

  const formOnChangeHandler = useMemo(
    () => withDebounce(handleSubmit(submitSearchData)),
    [handleSubmit, submitSearchData]
  );

  if (
    !featureAvailabilityWithRegions(
      [enumKeyByValue(Role, Role.Administrator), enumKeyByValue(Role, Role.RegionAdministrator)],
      currentUser
    )
  ) {
    return <></>;
  }

  const handleHeaderClick = (header: { label: string; key: string; sortable: boolean }) => {
    if (!header.sortable) {
      return;
    }

    const newOrderType =
      sort.keyToSort === header.key && sort.orderType === OrderType.ASC
        ? OrderType.DESC
        : OrderType.ASC;

    setSort({
      keyToSort: header.key,
      orderType: newOrderType,
    });

    dispatch(
      userListActions.fetchUserPagedList({
        orderBy: header.key,
        orderType: newOrderType,
        ...getPagedRequestParams(userList),
      })
    );
  };

  const handleMultipleUsersDelete = () => {
    dispatch(userListActions.removeUsers({ userIds: selectedUsers.map(Number) }))
      .unwrap()
      .then(() => {
        setValue(
          'selectedUsers',
          getValues('selectedUsers').filter((u) => !selectedUsers.includes(u))
        );
        dispatch(
          userListActions.fetchUserPagedList({
            orderBy: sort.keyToSort,
            orderType: sort.orderType,
            search: searchQuery,
            ...getPagedRequestParams(userList),
          })
        );
      });
    setIsDeleteUserModalOpen(false);
  };

  const handleSingleUserDeleteInitiate = (id: number) => {
    setValue('selectedUsers', [id.toString()]);
    setIsDeleteUserModalOpen(true);
  };

  const handleEditClick = (user: UserSummaryDto) => {
    navigate(generatePath(RouteConstants.EditUser, { userId: user.id.toString() }));
  };

  return (
    <div className={styles[PARENT_CLASSNAME]}>
      <FormProvider {...formMethods}>
        <form
          onChange={formOnChangeHandler}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              event.preventDefault();
            }
          }}
          className={styles[`${PARENT_CLASSNAME}__flex-row`]}>
          <div className={styles[`${PARENT_CLASSNAME}__search`]}>
            <SearchField
              name="search"
              placeholder={l('_SearchUsers')}
              onClear={() => {
                setSearchParams({ key: SearchParamKey.Search, value: null });
              }}
            />
          </div>
          <div className={styles[`${PARENT_CLASSNAME}__flex-row`]}>
            <div className={styles[`${PARENT_CLASSNAME}__delete-button`]}>
              {isGlobalAdmin && (
                <Button
                  appearance={ButtonAppearance.DEFAULT_PRIMARY}
                  startIcon={<SvgEventDelete style={{ stroke: 'white' }} />}
                  onClick={() => setIsDeleteUserModalOpen(true)}
                  disabled={!isUsersSelected}>
                  {l('_DeleteSelected')}
                </Button>
              )}
            </div>
            <div className={styles[`${PARENT_CLASSNAME}__add-button`]}>
              <Button
                appearance={ButtonAppearance.DEFAULT_PRIMARY}
                startIcon={<SvgPlus />}
                type="submit"
                onClick={() => navigate(`${RouteConstants.AddUsers}`)}>
                {l('_AddUser')}
              </Button>
            </div>
          </div>
        </form>
        <div style={{ height: '10px' }} />
        <Card
          hasShadow
          cornersVariation={CardCornersVariation.Secondary}
          variation={CardVariation.TertiarySpacing}>
          <>
            <table className="table-primary table-primary--fixed table-primary--secondary-theme table-primary--list">
              <colgroup>
                {isGlobalAdmin && <col className="table-primary__col--action" />}
                <col />
                <col />
                <col />
                {isGlobalAdmin && <col className="table-primary__col--additional-data" />}
              </colgroup>
              <thead>
                <tr>
                  {headers.map((header, index) => (
                    <th
                      key={index}
                      className={`${
                        index === 0 && isGlobalAdmin
                          ? styles[`${PARENT_CLASSNAME}__table-header--checkbox`]
                          : header.sortable
                          ? styles[`${PARENT_CLASSNAME}__table-header`]
                          : ''
                      }`}
                      onClick={() => header.sortable && handleHeaderClick(header)}>
                      <span className="heading-3 heading--table-header">{header.label}</span>
                      {header.sortable &&
                        (sort.keyToSort !== header.key ? (
                          <SvgSorting
                            className={`${styles[`${PARENT_CLASSNAME}__icon`]} ${
                              styles[`${PARENT_CLASSNAME}__icon--default`]
                            }`}
                          />
                        ) : sort.orderType === OrderType.ASC ? (
                          <SvgSortingAscendant
                            className={`${styles[`${PARENT_CLASSNAME}__icon`]} ${
                              styles[`${PARENT_CLASSNAME}__icon--asc`]
                            }`}
                          />
                        ) : (
                          <SvgSortingAscendant
                            className={`${styles[`${PARENT_CLASSNAME}__icon`]} ${
                              styles[`${PARENT_CLASSNAME}__icon--desc`]
                            }`}
                          />
                        ))}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {userList.data?.map((user, index) => (
                  <tr key={index}>
                    {canDeleteUser(currentUserRoleKey, user.role) ? (
                      <td className={styles[`${PARENT_CLASSNAME}__checkbox`]}>
                        <FormCheckbox
                          name="selectedUsers"
                          options={[{ value: user.id.toString(), viewValue: '' }]}
                          className={styles[`${PARENT_CLASSNAME}__checkbox`]}
                        />
                      </td>
                    ) : isGlobalAdmin ? (
                      <td />
                    ) : null}
                    <td>
                      <CellValue
                        value={user.email}
                        theme={CellValueTheme.Secondary}
                        alignment={Alignment.Left}
                      />
                    </td>
                    <td>
                      <CellValue
                        value={(user.regions ?? []).map((region) => region.name).join(', ')}
                        theme={CellValueTheme.Secondary}
                        alignment={Alignment.Left}
                      />
                    </td>
                    <td>
                      <CellValue
                        value={user.role}
                        theme={CellValueTheme.Secondary}
                        alignment={Alignment.Left}
                      />
                    </td>
                    {isGlobalAdmin ? (
                      <td className={styles[`${PARENT_CLASSNAME}__action-buttons`]}>
                        {canEditUser(currentUserRoleKey, user.role) && (
                          <Button
                            style={{ paddingBottom: '1.6rem' }}
                            onClick={() => handleEditClick(user)}
                            startIcon={
                              <SvgEditOutline
                                style={{ width: '18px', height: '18px', marginBottom: '10px' }}
                              />
                            }
                            appearance={ButtonAppearance.CLEAN}
                          />
                        )}
                        {canDeleteUser(currentUserRoleKey, user.role) && (
                          <Button
                            style={{ paddingBottom: '1.6rem' }}
                            startIcon={
                              <SvgEventDelete
                                style={{ width: '18px', height: '18px', marginBottom: '10px' }}
                              />
                            }
                            onClick={() => handleSingleUserDeleteInitiate(user.id)}
                            appearance={ButtonAppearance.CLEAN}
                          />
                        )}
                      </td>
                    ) : null}
                  </tr>
                ))}
              </tbody>
            </table>
            {userList.metadata && <TablePagination paginationInfo={userList.metadata} />}
          </>
        </Card>
      </FormProvider>
      <BaseModal
        size={ModalSize.Small}
        title={l('_ConfirmDeletion')}
        buttonConfirmLabel={l('_Confirm')}
        buttonCancelLabel={l('_Cancel')}
        onCloseButtonClick={() => setIsDeleteUserModalOpen(false)}
        onConfirm={handleMultipleUsersDelete}
        onCancel={() => setIsDeleteUserModalOpen(false)}
        isOpen={isDeleteUserModalOpen}>
        {l('_TheFollowingUsersWillBeDeleted')}
        <br />
        <br />
        <div>
          {selectedUsers.map((userId) => {
            const user = userList.data?.find((u) => u.id === Number(userId));
            return <div key={userId}>{user?.email}</div>;
          })}
        </div>
      </BaseModal>
    </div>
  );
};
