import { SVGProps } from 'react';
const SvgEditOutline = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <g id="Icon/Outline/edit-outline" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Group" transform="translate(-0.000000, -0.000000)" fill="#474747" fillRule="nonzero">
        <g id="edit">
          <path
            d="M21.0498029,22.5197549 L21.0310716,9.25873106 L22.5,9.25873106 L22.5,22.5483729 C22.5,23.3506658 21.8503196,24 21.0498029,24 L0,24 L0,2.95064035 C0,2.14933426 0.649680449,1.50000001 1.45019718,1.50000001 L14.7257616,1.50000001 L14.7257616,2.98024526 L1.45019718,2.98024526 L1.47681535,22.5197549 L21.0498029,22.5197549 Z M9.47831449,13.4648001 L10.9778896,11.9648001 L12.0385891,13.0258 L10.539014,14.5258 L9.47831449,13.4648001 Z M22.9393006,0 L24.0000001,1.06100001 L22.8053386,2.25500001 L22.055551,3.00500002 L20.5599748,4.50100002 L19.8061883,5.25500002 L13.1280805,11.9360001 L12.067381,10.875 L18.7474883,4.19300002 L19.4972759,3.44300002 L20.996851,1.94300001 L21.7466385,1.19300001 L22.9393006,0 Z"
            id="Combined-Shape"
          />
        </g>
      </g>
    </g>
  </svg>
);
export default SvgEditOutline;
