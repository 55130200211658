import { StateManagerProps } from 'react-select/dist/declarations/src/useStateManager';
import { FormControlPropsBase } from '../shared-types';
import { SelectOption } from '@app/shared/models/option';
import { FC } from 'react';
import {
  Checkbox,
  FormControl,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { PARENT_CLASSNAME } from '@app/shared/components/form-controls/form-field/FormField';
import styles from '../form-field.module.scss';
import classNames from 'classnames';
import FieldLabel from '../field-label/FieldLabel';
import { useController } from 'react-hook-form';

interface FormSelectMultipleProps extends FormControlPropsBase, StateManagerProps {
  options: SelectOption[];
  name: string;
  disabled?: boolean;
  inlineLabel?: boolean;
  ariaLabel?: string;
  onSelectedValuesChange?: (value: string[]) => void;
}

export const FormSelectMultiple: FC<FormSelectMultipleProps> = ({
  name,
  defaultValue,
  label,
  labelIcon,
  options,
  rules,
  required,
  isGapless = false,
  disabled = false,
  ariaLabel = undefined,
  inlineLabel = false,
  onSelectedValuesChange,
}) => {
  const { field } = useController({ name, rules, defaultValue });

  const handleChange = (event: SelectChangeEvent<typeof field.value>) => {
    const {
      target: { value },
    } = event;

    const newValue = typeof value === 'string' ? value.split(',') : value;
    field.onChange(newValue);

    if (onSelectedValuesChange) {
      onSelectedValuesChange(newValue);
    }
  };

  return (
    <div
      className={classNames(styles[PARENT_CLASSNAME], {
        [styles[`${PARENT_CLASSNAME}--gapless`]]: isGapless,
        [styles[`${PARENT_CLASSNAME}--inline-label`]]: inlineLabel,
      })}>
      {label && (
        <div className={classNames({ [styles[`${PARENT_CLASSNAME}__label`]]: !inlineLabel })}>
          <FieldLabel
            name={name}
            inlineLabel={inlineLabel}
            required={required ?? (rules && 'required' in rules)}>
            {labelIcon}
            {label}
          </FieldLabel>
        </div>
      )}

      <FormControl sx={{ m: 1, width: 300 }}>
        <Select
          {...field}
          id="multiple-checkbox"
          multiple
          disabled={disabled}
          aria-label={ariaLabel}
          input={<OutlinedInput label={label} />}
          value={field.value ?? ''}
          renderValue={(selected) => selected.join(', ')}
          onChange={handleChange}>
          {options.map((option) => (
            <MenuItem key={option.viewValue} value={option.viewValue}>
              <Checkbox
                checked={
                  option.viewValue !== undefined &&
                  field.value.includes(option.viewValue.toString())
                }
              />
              <ListItemText primary={option.viewValue} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};
