import { useAppDispatch, useAppSelector } from '@app/core/hooks/redux-hooks';
import {
  CalcMethod,
  CapitalStructureEventSeverity,
  EventChangedType,
  EventKey,
  InstrumentRank,
  InstrumentType,
  OwnerType,
  StructureDimension,
} from '@app/shared/models/contracts/enums/shared-enums';
import { FC, useEffect, useState } from 'react';
import { useDrop } from 'react-dnd';
import { v4 as uuidv4 } from 'uuid';
import * as capitalStructureActions from '@core/store/capital-structure-slice';
import {
  enumKeyByValue,
  getNewInstrument,
  getProjectDraftWithInstrumentAddedToEvent,
  getProjectDraftWithAddedInstrument,
} from '@app/shared/helpers';
import styles from '../build-structure-tab/capital-structure.module.scss';
import classNames from 'classnames';
import { uiValuesSlice } from '@core/store/ui-values-slice';
import Button from '@app/shared/components/button/Button';
import { calculateCapitalStructureDimensions2 } from '../../capital-structure-block-size-calculator';
import { Tooltip, TooltipPlacement } from '@app/shared/components/tooltip/Tooltip';
import { ButtonAppearance, ButtonSize } from '@app/shared/components/button/button-enums';
import {
  CapitalStructureEventDto,
  CapitalStructureInstrumentDefinitionDto,
  CapitalStructureRankInstrumentDto,
} from '@app/shared/models/contracts/capital-structure-debt-instrument-dto';
import { CapitalStructure2, InstrumentBlock2 } from '../../capital-structure-types';
import {
  amountValueFormatterProps,
  dateFormatter,
  numberValueFormatter,
  percentageValueFormatterProps,
} from '@app/shared/formatters';
import { getCalculatedProjectValueInPercent } from '@app/core/store/pwerm-calculation-slice-selectors';
import { getEventInstrumentsFromEvent } from '@app/core/store/capital-structure-slice-selectors';
import { DeleteEventConfirmModal } from './delete-event/DeleteEventConfirmModal';
import { CopyEventFormModal } from './copy-event/CopyEventFormModal';
import { UnlinkEventFormModal } from './unlink-event/UnlinkEventFormModal';
import SvgEventDelete from '@app/shared/icons/EventDelete';
import SvgEventCopy from '@app/shared/icons/EventCopy';
import SvgEventUnlink from '@app/shared/icons/EventUnlink';
import SvgExclamationMarkCircled from '@app/shared/icons/ExclamationMarkCircled';
import SvgWarningStatus from '@app/shared/icons/WarningStatus';
import SvgChevronSidebar from '@app/shared/icons/ChevronSidebar';
import { ExpandStructure } from './expand-structure/ExpandStructure';
import { EditEventModal } from './edit-event/EditEventModal';
import FaultIcon from './FaultIcon';
import EventActionIcon from './EventActionIcon';
import { useLocale } from '@app/core/hooks/useLocale';
import SvgEventInformation from '@app/shared/icons/EventInformation';
import useIsErfProject from '@app/shared/hooks/useIsErfProject';
import { DevFeature, useDevFeatures } from '@app/core/utils/dev-feature';
import SvgAdded from '@app/shared/icons/AddedIcon';
import SvgRemoved from '@app/shared/icons/RemovedIcon';
import SvgAmended from '@app/shared/icons/AmendedIcon';
import { cleanMovements } from './utils/clean-adjustments';
import { cleanFaultsAndMergeInstrumentDefinitions } from './utils/clean-faults-and-merge-definitions';
import { ProjectDto } from '@app/shared/models/contracts/project-dto';

export type EventUpdateState = Pick<
  CapitalStructureEventDto,
  'date' | 'narrative' | 'movements' | 'ranks'
> | null;

interface CapitalStructureOriginalProps {
  buildStructureEvent: CapitalStructureEventDto;
  instrumentDefinitions: Record<string, CapitalStructureInstrumentDefinitionDto>;
  isLastEventRelated: boolean;
  dragAreaHeight: number;
  shouldDisplayUnlinkButton: boolean;
  eventSetId: string;
}

const CapitalStructureErf: FC<CapitalStructureOriginalProps> = ({
  buildStructureEvent,
  instrumentDefinitions,
  isLastEventRelated,
  dragAreaHeight,
  shouldDisplayUnlinkButton = true,
  eventSetId,
}) => {
  const dispatch = useAppDispatch();
  const { l } = useLocale();
  const { isDevFeatureEnabled } = useDevFeatures();
  const isErfEnabled = isDevFeatureEnabled(DevFeature.ERF);
  const project = useAppSelector((state) => state.project.projectDraft);
  const capitalStructureKey = project.pwermInput.cases[0].capitalStructureId;
  const capitalStructure = useAppSelector(
    (state) => state.capitalStructure.values.buildStructures[capitalStructureKey]
  );
  const valuationDate = new Date(project.valuationDate);
  const activeInstrumentId = useAppSelector((state) => state.uiValues.activeInstrument);
  const capTable = useAppSelector((state) => state.capitalStructure.values.capTable);
  const isOpmOnly = project.details.calcMethod === CalcMethod.OPM;
  const eventInstruments = getEventInstrumentsFromEvent(buildStructureEvent, instrumentDefinitions);
  const isErfProject = useIsErfProject();
  const hasMultipleCases = project.pwermInput.cases.length > 1;
  const isEventDateBeforeValuationDate = buildStructureEvent.date < valuationDate.toISODateString();

  const [isCopyEventModalOpen, setIsCopyEventModalOpen] = useState(false);
  const [isConfirmDeleteEventModalOpen, setIsConfirmDeleteEventModalOpen] = useState(false);
  const [isStructureExpanded, setIsStructureExpanded] = useState(false);
  const [isUnlinkEventModalOpen, setIsUnlinkEventModalOpen] = useState(false);
  const [isEditEventModalOpen, setIsEditEventModalOpen] = useState(false);
  const [openingEventState, setOpeningEventState] = useState<EventUpdateState | null>(null);
  const [openingProjectState, setOpeningProjectState] = useState<ProjectDto>(project);
  const [currentEventState, setCurrentEventState] = useState<EventUpdateState | null>(null);

  useEffect(() => {
    if (buildStructureEvent) {
      setCurrentEventState({
        date: buildStructureEvent.date,
        narrative: buildStructureEvent.narrative,
        movements: cleanMovements(buildStructureEvent.movements),
        ranks: cleanFaultsAndMergeInstrumentDefinitions(
          buildStructureEvent.ranks,
          instrumentDefinitions
        ),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buildStructureEvent]);

  const [{ isActive }, drop] = useDrop(
    () => ({
      accept: [
        InstrumentType.ShareholderLoanNotes,
        InstrumentType.PreferredShares,
        InstrumentType.OrdinaryEquity,
      ],
      drop(_item: string, monitor) {
        const draggedInstrumentType = monitor.getItemType() as InstrumentType;
        addInstrument(draggedInstrumentType);
      },
      collect: (monitor) => ({
        isActive: monitor.canDrop() && monitor.isOver(),
      }),
    }),
    [project, eventInstruments]
  );

  const handleInstrumentSelection = (instrumentId: string): void => {
    dispatch(
      uiValuesSlice.actions.setActiveInstrument({ instrumentId, eventId: buildStructureEvent.id })
    );
    // make a copy of buildStructureEvent but with only date, narrative, ranks and movement
    const buildStructureEventCopy = {
      date: buildStructureEvent.date,
      narrative: buildStructureEvent.narrative,
      ranks: cleanFaultsAndMergeInstrumentDefinitions(
        buildStructureEvent.ranks,
        instrumentDefinitions
      ),
      movements: cleanMovements(buildStructureEvent.movements),
    };
    if (!isEditEventModalOpen) {
      isErfProject && setOpeningEventState(buildStructureEventCopy);
      isErfProject && setOpeningProjectState(project);
    }
    isErfProject && setIsEditEventModalOpen(true);
  };

  const addInstrument = async (instrumentType: InstrumentType, eventId?: string): Promise<void> => {
    const instrumentId = uuidv4();
    const newInstrument = getNewInstrument(project, eventInstruments, instrumentType, instrumentId);

    const updateProjectDraft = eventId
      ? getProjectDraftWithInstrumentAddedToEvent({ draft: project, eventId, newInstrument })
      : getProjectDraftWithAddedInstrument({ draft: project, newInstrument });

    await dispatch(
      capitalStructureActions.calculateBuildStructure({
        project: updateProjectDraft,
        ignoreErrors: true,
      })
    );

    dispatch(
      uiValuesSlice.actions.setActiveInstrument({ instrumentId, eventId: buildStructureEvent.id })
    );
  };

  const structure = calculateCapitalStructureDimensions2(
    buildStructureEvent.ranks,
    isErfProject
      ? dragAreaHeight - StructureDimension.ErfFixedHeightElements
      : dragAreaHeight - StructureDimension.NonErfFixedHeightElements,
    StructureDimension.Width,
    StructureDimension.MinHeight,
    StructureDimension.MinWidth,
    false
  );

  const modalStructure = calculateCapitalStructureDimensions2(
    buildStructureEvent.ranks,
    StructureDimension.ModalHeight,
    StructureDimension.ModalWidth,
    StructureDimension.MinHeight,
    StructureDimension.MinWidth,
    false
  );

  const createTooltipBannerTitle = (
    instrument: InstrumentBlock2<CapitalStructureRankInstrumentDto>
  ) => {
    let label = '';
    let icon: JSX.Element = <></>;

    const appendLabel = (existingLabel: string, newPart: string) => {
      return existingLabel
        ? l('_ItemCombination', { item1: existingLabel, item2: newPart })
        : newPart;
    };

    // Check for instrument added, and return early if so
    if (instrument.changeFlags & EventChangedType.InstrumentAdded) {
      label = appendLabel(label, l('_NewInstrumentAdded'));
      icon = <SvgAdded />;
      return { label, icon };
    }

    // Check for instrument fully redeemed, and return early if so
    if (instrument.changeFlags & EventChangedType.FullyRedeemed) {
      label = appendLabel(label, l('_InstrumentRemoved'));
      icon = <SvgRemoved />;
      return { label, icon };
    }

    // Check for value increase
    if (instrument.changeFlags & EventChangedType.ValueIncrease) {
      label = appendLabel(label, l('_IncrementalInvestment'));
      icon = <SvgAmended />;
    }

    // Check for value decrease
    if (instrument.changeFlags & EventChangedType.ValueDecrease) {
      label = appendLabel(label, l('_CapitalReturn'));
      icon = <SvgAmended />;
    }

    // Check for coupon updates or feature changes
    if (instrument.changeFlags & EventChangedType.CouponUpdated) {
      label = appendLabel(label, l('_ChangeInFeatures'));
      icon = <SvgAmended />;
    }

    // Check for changes that have been made, but no label has been set (e.g. a change in amount for OE, or change in shares for non-OE)
    if (instrument.changeFlags !== EventChangedType.None && label === '') {
      label = appendLabel(label, l('_ChangeInFeatures'));
      icon = <SvgAmended />;
    }

    return { label, icon };
  };

  const createInstrumentBlockTooltip = (
    instrument: InstrumentBlock2<CapitalStructureRankInstrumentDto>
  ) => {
    return (
      <>
        {!isInitialStructure && instrument.changeFlags !== EventChangedType.None && (
          <div
            className={classNames(styles['instrument-tooltip-banner'], {
              [styles['instrument-tooltip-banner--sweet-equity']]:
                instrument.isSweetEquity &&
                instrument.type === enumKeyByValue(InstrumentType, InstrumentType.OrdinaryEquity),
              [styles['instrument-tooltip-banner--shareholder-loan']]:
                instrument.type ===
                enumKeyByValue(InstrumentType, InstrumentType.ShareholderLoanNotes),
              [styles['instrument-tooltip-banner--preferred-shares']]:
                instrument.type === enumKeyByValue(InstrumentType, InstrumentType.PreferredShares),
              [styles['instrument-tooltip-banner--redeemed']]:
                (instrument.changeFlags & EventChangedType.FullyRedeemed) ===
                EventChangedType.FullyRedeemed,
            })}>
            <div>{createTooltipBannerTitle(instrument).icon} </div>
            <div className={styles['instrument-tooltip-banner__text']}>
              {createTooltipBannerTitle(instrument).label}
            </div>
          </div>
        )}
        <div className={styles['instrument-details']}>
          <h2 className={styles['instrument-details__title']}>
            {l('_InstrumentNarrativeInvestment', {
              instrumentNarrative: instrument.instrumentNarrative,
            })}
          </h2>
          <p className={styles['instrument-details__subtitle']}>
            {InstrumentType[instrument.type]}
          </p>
          <ul className={styles['instrument-details__owners-list']}>
            {instrument.ownershipAfterMovements &&
              Object.entries(instrument.ownershipAfterMovements).map(([owner, amount], index) => {
                const ownerKey = owner as keyof typeof OwnerType;
                return (
                  <li key={index} className={styles['instrument-details__owners-list-item']}>
                    {OwnerType[ownerKey]}{' '}
                    <strong>
                      {numberValueFormatter({
                        value: Number(amount.amount),
                        ...amountValueFormatterProps,
                      })}
                    </strong>
                  </li>
                );
              })}
          </ul>
          <div className={styles['instrument-details__percentages-container']}>
            {instrument.proportionOfType !== null && (
              <div>
                {`${numberValueFormatter({
                  value: getCalculatedProjectValueInPercent(instrument.proportionOfType)!,
                  ...percentageValueFormatterProps,
                })} of ${InstrumentType[instrument.type]}`}
              </div>
            )}
            {instrument.proportionOfTotalEquity !== null && (
              <div>
                {`${numberValueFormatter({
                  value: getCalculatedProjectValueInPercent(instrument.proportionOfTotalEquity)!,
                  ...percentageValueFormatterProps,
                })} of Total Equity`}
              </div>
            )}
          </div>
        </div>
      </>
    );
  };

  const getInstrumentNarrative = (
    instrumentNarrative: string,
    changeFlags: EventChangedType
  ): JSX.Element => {
    if (changeFlags === EventChangedType.None || isInitialStructure) {
      return <div className={styles['instrument-narrative']}>{instrumentNarrative}</div>;
    }

    return (changeFlags & EventChangedType.InstrumentAdded) === EventChangedType.InstrumentAdded ? (
      <>
        <SvgAdded />
        <div className={styles['instrument-narrative']}>{instrumentNarrative}</div>
      </>
    ) : (changeFlags & EventChangedType.FullyRedeemed) === EventChangedType.FullyRedeemed ? (
      <>
        <SvgRemoved />
        <div className={styles['instrument-narrative']}>{instrumentNarrative}</div>
      </>
    ) : (
      <>
        <SvgAmended />
        <div className={styles['instrument-narrative']}>{instrumentNarrative}</div>
      </>
    );
  };

  const isInitialStructure = buildStructureEvent.id === EventKey.InitialCapitalStructure;

  const createInstrumentBlock = (
    instrument: InstrumentBlock2<CapitalStructureRankInstrumentDto>,
    key: number,
    isModal: boolean
  ) => {
    return (
      <Tooltip
        key={key}
        content={createInstrumentBlockTooltip(instrument)}
        useFixedPositioning
        placement={
          isModal
            ? TooltipPlacement.Right
            : isLastEventRelated
            ? TooltipPlacement.Left
            : TooltipPlacement.Right
        }
        className={styles['graph__item-container']}
        style={{ width: instrument.width }}>
        <Button
          data-testid="instrument-block"
          appearance={ButtonAppearance.CLEAN}
          onClick={() => handleInstrumentSelection(instrument.instrumentId)}
          key={key}
          ignoreReadOnly
          className={classNames(styles['graph__item'], {
            [styles['graph__item--sweet-equity']]:
              instrument.isSweetEquity &&
              instrument.type === enumKeyByValue(InstrumentType, InstrumentType.OrdinaryEquity),
            [styles['graph__item--shareholder-loan']]:
              instrument.type ===
              enumKeyByValue(InstrumentType, InstrumentType.ShareholderLoanNotes),
            [styles[`${'graph__item'}--preferred-shares`]]:
              instrument.type === enumKeyByValue(InstrumentType, InstrumentType.PreferredShares),
            [styles[`${'graph__item'}--active`]]:
              instrument.instrumentId === activeInstrumentId?.instrumentId &&
              buildStructureEvent.id === activeInstrumentId?.eventId &&
              !isErfProject,
            [styles[`${'graph__item'}--active-overview`]]:
              instrument.instrumentId === activeInstrumentId?.instrumentId &&
              buildStructureEvent.id === activeInstrumentId?.eventId &&
              isErfProject &&
              !isModal,
            [styles[`${'graph__item'}--active-modal`]]:
              instrument.instrumentId === activeInstrumentId?.instrumentId &&
              buildStructureEvent.id === activeInstrumentId?.eventId &&
              isErfProject &&
              isModal,
            [styles[`${'graph__item'}--unchanged`]]:
              instrument.changeFlags === EventChangedType.None,
            [styles[`${'graph__item'}--redeemed`]]:
              (instrument.changeFlags & EventChangedType.FullyRedeemed) ===
              EventChangedType.FullyRedeemed,
            [styles[`${'graph__item'}--newly-added`]]:
              (instrument.changeFlags & EventChangedType.InstrumentAdded) ===
                EventChangedType.InstrumentAdded && !isInitialStructure,
            [styles['graph__item--changed']]:
              instrument.changeFlags !== EventChangedType.None &&
              !isInitialStructure &&
              (instrument.changeFlags & EventChangedType.InstrumentAdded) !==
                EventChangedType.InstrumentAdded &&
              (instrument.changeFlags & EventChangedType.FullyRedeemed) !==
                EventChangedType.FullyRedeemed,
          })}>
          <div className={styles['graph__item-text']}>
            {getInstrumentNarrative(instrument.instrumentNarrative, instrument.changeFlags)}
          </div>
        </Button>
      </Tooltip>
    );
  };

  const informationFaults = capitalStructure.faults.filter(
    (fault) =>
      fault.severity === CapitalStructureEventSeverity.Information &&
      fault.event === buildStructureEvent.id &&
      (fault.eventSet === null || fault.eventSet === eventSetId)
  );

  const warningFaults = capitalStructure.faults.filter(
    (fault) =>
      fault.severity === CapitalStructureEventSeverity.Warning &&
      fault.event === buildStructureEvent.id &&
      (fault.eventSet === null || fault.eventSet === eventSetId)
  );

  const errorFaults = capitalStructure.faults.filter(
    (fault) =>
      fault.severity === CapitalStructureEventSeverity.Error &&
      fault.event === buildStructureEvent.id &&
      (fault.eventSet === null || fault.eventSet === eventSetId)
  );

  const renderRanks = (
    structure: CapitalStructure2<CapitalStructureRankInstrumentDto>,
    isModal: boolean
  ) => {
    const ranksWithoutOE = structure.ranks.filter(
      (rank) => rank.rank !== InstrumentRank.OrdinaryEquity
    );
    const rankWithOE = structure.ranks.find((rank) => rank.rank === InstrumentRank.OrdinaryEquity);
    const ranksInOrder = ranksWithoutOE.sort((a, b) => a.rank - b.rank).reverse();
    if (rankWithOE) {
      ranksInOrder.unshift(rankWithOE);
    }
    return ranksInOrder.map((rank) => {
      return (
        <div
          key={rank.rank}
          style={{ height: rank.height }}
          className={styles['graph__rank-block']}>
          {rank.instrumentBlocks
            .sort((a, b) => a.order - b.order)
            .map((instrument, i) => {
              const instrumentNarrative =
                instrumentDefinitions[instrument.instrumentId]?.instrumentNarrative;
              const instrumentType = instrumentDefinitions[instrument.instrumentId]?.type;
              const isSweetEquity =
                instrumentDefinitions[instrument.instrumentId]?.isSweetEquity ?? false;
              const instrumentBlock = {
                ...instrument,
                instrumentNarrative,
                type: instrumentType,
                isSweetEquity,
              };
              return createInstrumentBlock(instrumentBlock, i, isModal);
            })}
        </div>
      );
    });
  };

  const modalRanks = renderRanks(modalStructure, true);

  const hasInstruments = structure.ranks.length > 0;
  const shouldShowEventArea = (isInitialStructure && hasInstruments) || !isInitialStructure;

  return (
    <div
      ref={drop}
      data-testid="build-structure-drag-area"
      className={classNames(styles['graph'], {
        [styles['graph--active']]: isActive,
      })}>
      {shouldShowEventArea && (
        <div
          style={{ display: 'flex', height: dragAreaHeight }}
          className={classNames({
            [isErfProject ? styles['graph__wrapper-erf'] : styles['graph__wrapper']]: true,
          })}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div
              className={styles['info-title']}
              style={{
                width: `${StructureDimension.Width}px`,
              }}>
              {isErfProject && (
                <div
                  className={styles['graph__instrument-title']}
                  title={buildStructureEvent.narrative}>
                  {buildStructureEvent.narrative}
                </div>
              )}
              {isErfProject && (
                <div className={styles['event-buttons']}>
                  <FaultIcon
                    severity={CapitalStructureEventSeverity.Information}
                    icon={<SvgEventInformation />}
                    label="_Information"
                    eventFaults={informationFaults}
                    instrumentDefinitions={instrumentDefinitions}
                  />
                  <FaultIcon
                    severity={CapitalStructureEventSeverity.Warning}
                    icon={<SvgWarningStatus />}
                    label="_Warnings"
                    eventFaults={warningFaults}
                    instrumentDefinitions={instrumentDefinitions}
                  />
                  <FaultIcon
                    severity={CapitalStructureEventSeverity.Error}
                    icon={<SvgExclamationMarkCircled />}
                    label="_Errors"
                    eventFaults={errorFaults}
                    instrumentDefinitions={instrumentDefinitions}
                  />
                  {!isOpmOnly && !isEventDateBeforeValuationDate && (
                    <>
                      <EventActionIcon
                        condition={shouldDisplayUnlinkButton}
                        action={() => setIsUnlinkEventModalOpen(true)}
                        icon={<SvgEventUnlink />}
                        buildStructureEventId={buildStructureEvent.id}
                      />
                      <EventActionIcon
                        condition={hasMultipleCases}
                        action={() => setIsCopyEventModalOpen(true)}
                        icon={<SvgEventCopy />}
                        buildStructureEventId={buildStructureEvent.id}
                      />
                    </>
                  )}
                  <EventActionIcon
                    condition
                    action={() => setIsConfirmDeleteEventModalOpen(true)}
                    icon={<SvgEventDelete />}
                    buildStructureEventId={buildStructureEvent.id}
                  />
                </div>
              )}
            </div>
            <div
              style={{ width: structure.maxWidth }}
              className={classNames({ [styles['no-instruments-event-text']]: !hasInstruments })}>
              {hasInstruments && renderRanks(structure, false)}
              {!hasInstruments && l('_PreviousEventHasRemovedAllInstruments')}
            </div>
            {isErfProject && (
              <div className={styles['graph__date']}>
                {dateFormatter(buildStructureEvent.date.toString(), { dateStyle: 'short' })}
              </div>
            )}
          </div>
          {isStructureExpanded && (
            <ExpandStructure
              capTableEvent={capTable?.eventSets[eventSetId]?.events[buildStructureEvent.date]}
              buildStructureEvent={buildStructureEvent}
              instrumentDefinitions={instrumentDefinitions}
            />
          )}
          {isErfEnabled && hasInstruments && (
            <div className={styles['expand-button']}>
              <Button
                startIcon={
                  <SvgChevronSidebar
                    className={
                      isStructureExpanded
                        ? styles[`sidebar-chevron-expanded`]
                        : styles[`sidebar-chevron-collapsed`]
                    }
                  />
                }
                onClick={() => setIsStructureExpanded(!isStructureExpanded)}
                appearance={ButtonAppearance.CLEAN}
                size={ButtonSize.FULL_HEIGHT}
              />
            </div>
          )}
        </div>
      )}
      <UnlinkEventFormModal
        setIsOpen={setIsUnlinkEventModalOpen}
        isOpen={isUnlinkEventModalOpen}
        eventId={buildStructureEvent.id}
      />
      <CopyEventFormModal
        setIsOpen={setIsCopyEventModalOpen}
        isOpen={isCopyEventModalOpen}
        eventId={buildStructureEvent.id}
        eventDate={buildStructureEvent.date}
        eventNarrative={buildStructureEvent.narrative}
      />
      <DeleteEventConfirmModal
        setIsOpen={setIsConfirmDeleteEventModalOpen}
        isOpen={isConfirmDeleteEventModalOpen}
        eventId={buildStructureEvent.id}
        eventNarrative={buildStructureEvent.narrative}
      />
      <EditEventModal
        setIsOpen={setIsEditEventModalOpen}
        isOpen={isEditEventModalOpen && isErfProject}
        buildStructureEvent={buildStructureEvent}
        instrumentId={activeInstrumentId?.instrumentId}
        modalRanks={modalRanks}
        openingEventState={openingEventState}
        openingProjectState={openingProjectState}
        currentEventState={currentEventState}
        setOpeningProjectState={setOpeningProjectState}
        setOpeningEventState={setOpeningEventState}
      />
    </div>
  );
};

export default CapitalStructureErf;
