import { SVGProps } from 'react';
const SvgRemoved = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M1.752 1.752C2.328 1.176 2.992 0.736 3.744 0.432C4.464 0.144 5.216 0 6 0C6.784 0 7.536 0.144 8.256 0.432C9.008 0.736 9.672 1.176 10.248 1.752C10.824 2.328 11.264 2.992 11.568 3.744C11.856 4.464 12 5.216 12 6C12 6.784 11.856 7.536 11.568 8.256C11.264 9.008 10.824 9.672 10.248 10.248C9.672 10.824 9.008 11.264 8.256 11.568C7.536 11.856 6.784 12 6 12C5.216 12 4.464 11.856 3.744 11.568C2.992 11.264 2.328 10.824 1.752 10.248C1.176 9.672 0.736 9.008 0.432 8.256C0.144 7.536 0 6.784 0 6C0 5.216 0.144 4.464 0.432 3.744C0.736 2.992 1.176 2.328 1.752 1.752ZM3 6.744H9V5.244H3V6.744Z"
      fill="white"
    />
  </svg>
);
export default SvgRemoved;
