import { Environment } from '@environment';

export default {
  local: {
    apiUrl: 'https://localhost:7296/api',
    region: 'UK',
  },
  dev: {
    apiUrl: 'https://pwc-deals-dev-api.devbstaging.com/api',
    region: 'UK',
  },
  e2e: {
    apiUrl: 'https://pwc-deals-e2e-api.azurewebsites.net/api',
    region: 'UK',
  },
  uat: {
    apiUrl: 'https://pwc-deals-uat-api.devbstaging.com/api',
    region: 'UK',
  },
  dem: {
    apiUrl: 'https://pwc-deals-dem-api.devbstaging.com/api',
    region: 'UK',
  },
} as Environments;

export interface EnvironmentConfig {
  apiUrl: string;
  region: string;
  redirectUrl?: string;
}

type Environments = {
  [key in Environment]: EnvironmentConfig;
};
