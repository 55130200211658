import { useAppSelector } from './redux-hooks';
import { NETWORK_ROLL_OUT_REGION_OPTIONS } from '@app/shared/constants/dropdown-values/network-rollout-regions';
import { ROLE_OPTIONS } from '@app/shared/constants/dropdown-values/role-options';
import { Role } from '@app/shared/models/contracts/enums/shared-enums';

const useUserOptions = () => {
  const currentUser = useAppSelector((state) => state.userInfo).userInfo;
  const currentUserRoleKey = currentUser.role as keyof typeof Role;
  const currentUserRole = Role[currentUser.role as keyof typeof Role];
  const isGlobalAdmin = currentUserRole === Role.Administrator;
  const isRegionAdmin = currentUserRole === Role.RegionAdministrator;
  const userRegions = currentUser.regions;

  const roleOptions = ROLE_OPTIONS.filter(
    (option) => option.value !== Role.Administrator && option.value !== currentUserRoleKey
  );

  const regionOptions = isGlobalAdmin
    ? NETWORK_ROLL_OUT_REGION_OPTIONS
    : NETWORK_ROLL_OUT_REGION_OPTIONS.filter((option) =>
        userRegions?.some((region) => region.code === option.value)
      );

  return {
    isGlobalAdmin,
    isRegionAdmin,
    roleOptions,
    regionOptions,
    currentUserRole,
    currentUserRoleKey,
    currentUser,
  };
};

export default useUserOptions;
